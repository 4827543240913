import React from 'react'
import { Helmet } from 'react-helmet'

// TODO: Byt ut texten
function SignInMetaDescription() {
    return (
        <Helmet>
            <title>Logga in</title>
            <meta
                name="description"
                content="Inloggningssida."
                lang="sv"
            />
            <meta
                name="description"
                content="Log-in page."
                lang="en"
            />
        </Helmet>
    )
}

export default SignInMetaDescription