/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit"

const slice = createSlice({
    name: "productCompare",
    initialState: {
        productIds: [],
        showOverview: false,
        onComparePage: false
    },
    reducers: {
        addToCompare: (state, action) => {
            const result = [...state.productIds, action.payload]
            const distinctResult = [...new Set(result)]
            state.productIds = distinctResult
        },
        removeFromCompare: (state, action) => {
            state.productIds = [...state.productIds.filter(
                (element, index) => element !== action.payload
            )]
        },
        removeAllFromCompare: state => {
            state.productIds = []
        },
        setShowCompareOverview: (state, action) => {
            state.showOverview = action.payload
        },
        setIsOnComparePage: (state, action) => {
            state.onComparePage = action.payload
        }
    }
})

export const {
    addToCompare,
    removeFromCompare,
    setShowCompareOverview,
    removeAllFromCompare,
    setIsOnComparePage
} = slice.actions

export default slice.reducer
