/* eslint-disable react/destructuring-assignment */
import useStyles from "./styles"
import SideFilter from "./components/SideFilter"
// eslint-disable-next-line import/no-cycle
import { PageHandler, Products } from "../.."
import usePage from "../../../redux/hook/pageHook"
import { useIsSearchQueryEmpty, useSearchQuery, useSearchQuery2 } from "../../../redux/hook/searchHooks"
import { setSearchQuery } from "../../../redux/searchReducer"
import { sendGAEventProductSearchViaLink } from "../../../lib/helper/googleAnalyticsHelper"
import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { useUrlQuery, useUrlSearchQuery } from "../../../lib/hooks/urlQueryHooks"
import { useGetAltNameForProductsQuery, useGetReplacementProductsQuery } from "../../../redux/services/broditWebApi"
import NoIndexMetaData from "../../MetaData/NoIndexMetadata"
import FilterProductToolbar from "../FilterProductToolbar/FilterProductToolbar"
import { useGetProductsByFilterQuery } from "../../../redux/services/productInfoApi"
import { findObjectById, isProductIdRegex } from "../../../lib/helper/commonHelper"
import { isSearchQueryEmpty } from "../../../lib/helper/searchHelper"
import { setSearchQuery2 } from "../../../redux/search2Reducer"
import { useLoadPrices } from "../../../redux/hook/priceHooks"
import { setShowNavbarSearch } from "../../../redux/settingsReducer"
import { useDispatch } from "react-redux"
import {
    Box, Button, useMediaQuery, useTheme
} from "@mui/material"
import React, {
    useEffect, useMemo, useState
} from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import Drawer from "@mui/material/Drawer"
import CloseIcon from "@mui/icons-material/Close"

const FilterProducts = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const theme = useTheme()
    const page = usePage()
    const urlQuery = useUrlQuery()
    const href = new URL(window.location.href)
    const params = new URLSearchParams(href.search)
    const language = useLanguage()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(tabletBreakpoint))
    let {
        brand: urlSelectedBrand,
        model: urlSelectedModel,
        collections: urlSelectedColletion
    } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { data: altNameList } = useGetAltNameForProductsQuery()

    const fetchCollectionStructure = async () => {
        const response = await fetch(`/api/Collections/GetCollectionHierarchy?language=${language}`)
        return response.json()
    }

    const dirtyUrlSelectedBrand = urlSelectedBrand && urlSelectedBrand.replace("_", "%2B")
    const dirtyUrlSelectedModel = urlSelectedModel && urlSelectedModel.replace("_", "%2B").replace("£", "/")
    const dirtyUrlSelectedCollection = urlSelectedColletion && urlSelectedColletion.replace("_", "%2B").replace("£", "/")
    urlSelectedBrand = urlSelectedBrand && decodeURIComponent(dirtyUrlSelectedBrand)
    urlSelectedModel = urlSelectedModel && decodeURIComponent(dirtyUrlSelectedModel)
    urlSelectedModel = urlSelectedModel?.split("$")
    urlSelectedColletion = urlSelectedColletion && decodeURIComponent(dirtyUrlSelectedCollection)

    const [isUserShowingFilter, setIsUserShowingFilter] = useState(true)
    const [showFilter] = useState(true)
    const urlSearchQuery = useUrlSearchQuery(urlQuery)
    const isUrlSearchQueryEmpty = useIsSearchQueryEmpty(urlSearchQuery)
    const search1 = useSearchQuery()
    const search2 = useSearchQuery2()
    const allSearchQueriesCombined = useMemo(() => (
        Object.keys(search2).length > 0 ? search2 : search1
    ), [search1, search2])
    const isAllSearchQueryEmpty = useIsSearchQueryEmpty(allSearchQueriesCombined)
    const pagination = useMemo(() => {
        const offset = params.get("page") ? (parseInt(params.get("page"), 10) - 1) * page.limit : page.offset
        return { offset, limit: page.limit }
    }, [href, page.offset, page.limit])
    const getProductsByFilterRequest = useGetProductsByFilterQuery({
        filters: allSearchQueriesCombined, pagination, language
    })
    const {
        data: productsResult, isFetching, startedTimeStamp, error
    } = getProductsByFilterRequest
    const [forcedLoadingSpinner, setForcedLoadingSpinner] = useState(false)
    const loading = useMemo(
        () => !isAllSearchQueryEmpty && (forcedLoadingSpinner || isFetching),
        [isFetching, forcedLoadingSpinner, isAllSearchQueryEmpty]
    )
    const products = (productsResult?.products && !isAllSearchQueryEmpty && !error)
        ? productsResult?.products
        : []

    let productTotalCount = (productsResult?.pagedList?.response?.pagingHeader?.totalItems) ?? 0
    productTotalCount = (isAllSearchQueryEmpty || error) ? 0 : productTotalCount
    const showReplacementProds = (
        allSearchQueriesCombined?.texts?.length === 1
        && isProductIdRegex.test(allSearchQueriesCombined?.texts?.[0])
    )
    const { data: replacementProds } = useGetReplacementProductsQuery(
        allSearchQueriesCombined?.texts?.[0],
        { skip: !showReplacementProds }
    )

    const getUrl = (collection = null) => {
        let link = `/${language}/filter`
        if (location.search.split("=")[1] > Math.ceil(productTotalCount / page.userSelectedLimit) && productTotalCount > 0) {
            dispatch(setSearchQuery({ type: "filterProducts", value: { ...allSearchQueriesCombined, page: ["1"] } }))
            return `${location.pathname}?page=1`
        }

        const cleanBrands = allSearchQueriesCombined?.brands?.[0].replace("+", "_")
        const cleanModels = allSearchQueriesCombined?.models?.map(x => x?.replace("+", "_").replace("/", "£"))
        const cleanModelsString = cleanModels?.join("$")
        const cleanCollections = collection?.name?.replace("/", "£")
        if (allSearchQueriesCombined?.brands) link += `/brand/${encodeURIComponent(cleanBrands)}`
        if (allSearchQueriesCombined?.models) link += `/${encodeURIComponent(cleanModelsString)}`
        if (collection) link += `/collection/${encodeURIComponent(collection?.id)}/${cleanCollections || ""}`
        if (allSearchQueriesCombined?.years) link += `?steering=${allSearchQueriesCombined?.steering}&years=${allSearchQueriesCombined?.years}`
        if (allSearchQueriesCombined?.texts) link += `?texts=${allSearchQueriesCombined?.texts}${allSearchQueriesCombined?.steering !== undefined ? `&steering=${allSearchQueriesCombined?.steering?.[0]}%2C${allSearchQueriesCombined?.steering?.[1]}` : ""}`
        if (allSearchQueriesCombined?.page
            && (allSearchQueriesCombined?.years || allSearchQueriesCombined?.texts)) link += `&page=${allSearchQueriesCombined?.page}`
        if (allSearchQueriesCombined?.page
            && (!allSearchQueriesCombined?.years && !allSearchQueriesCombined?.texts)) link += `?page=${allSearchQueriesCombined?.page}`
        return link
    }

    const setInitialUrl = () => {
        const url = getUrl()
        if (url !== "" && url !== decodeURIComponent(location.pathname + location.search)) navigate(url)
    }

    const updateUrlParams = collection => {
        const url = getUrl(collection)
        if (url !== "" && decodeURIComponent(url) !== decodeURIComponent(location.pathname + location.search)) navigate(url)
    }

    const toggleFilter = () => {
        setIsUserShowingFilter(prev => !prev)
    }

    useLoadPrices(products.map(product => product.originalId))

    useEffect(() => {
        setForcedLoadingSpinner(true)
        const a = setTimeout(() => {
            setForcedLoadingSpinner(false)
        }, 100)
        return () => clearTimeout(a)
    }, [startedTimeStamp])

    useEffect(() => {
        setIsUserShowingFilter(!isSmallScreen)
    }, [isSmallScreen])

    useEffect(() => {
        const combinedSearchQuery = {
            ...!isUrlSearchQueryEmpty && urlSearchQuery,
            ...urlSelectedBrand && { brands: [urlSelectedBrand] },
            ...urlSelectedModel && { models: [...urlSelectedModel] },
            ...urlSelectedColletion && { collections: [urlSelectedColletion] }
        }
        if (!isSearchQueryEmpty(combinedSearchQuery)) {
            if (combinedSearchQuery?.collections !== undefined && !combinedSearchQuery?.collections?.[0].includes("s")) {
                combinedSearchQuery.collections[0] = `s${combinedSearchQuery.collections[0]} `
            }

            dispatch(
                setSearchQuery({
                    type: "filterProducts",
                    value: combinedSearchQuery,
                    clearFilter: true
                })
            )
            sendGAEventProductSearchViaLink()
        }

        setInitialUrl()

        return () => {
            dispatch(setShowNavbarSearch(false))
            dispatch(setSearchQuery({ type: "filterProducts", value: {} }))
        }
    }, [])

    useEffect(() => {
        if (allSearchQueriesCombined?.collections?.[0] !== undefined) {
            fetchCollectionStructure().then(result => {
                const res = findObjectById(result, allSearchQueriesCombined?.collections?.[0])
                if (res !== null) updateUrlParams(res)
                window.scroll(0, 0)
            })
        } else {
            updateUrlParams()
        }
    }, [allSearchQueriesCombined, language, products])

    useEffect(() => {
        if (Object.keys(search1).length > 0) {
            dispatch(setSearchQuery2({
                type: "filterProducts",
                value: {}
            }))
            dispatch(setSearchQuery2({
                type: "deviceSearchbar",
                value: {}
            }))
            dispatch(setSearchQuery2({
                type: "vehicleSearchbar",
                value: {}
            }))
        }
    }, [search1])

    return (
        <main className={classes.content}>
            {
                (
                    !isAllSearchQueryEmpty
                    || !isUrlSearchQueryEmpty
                )
                && <NoIndexMetaData />
            }
            <FilterProductToolbar
                toggleFilter={toggleFilter}
                productTotalCount={productTotalCount}
                className={classes.flexGrow1}
            />
            <Drawer onClose={() => setIsUserShowingFilter(false)} open={isUserShowingFilter && showFilter && isSmallScreen}>
                <div className={classes.buttonWrapper}>
                    <Button onClick={() => setIsUserShowingFilter(false)}>
                        Close
                        <CloseIcon />
                    </Button>
                </div>
                <SideFilter
                    productsResult={productsResult}
                />
            </Drawer>
            <Box className={classes.productBox}>
                {
                    isUserShowingFilter && showFilter && !isSmallScreen
                    && (
                        <SideFilter
                            productsResult={productsResult}
                        />
                    )
                }

                <Box className={classes.flexGrow1} style={{ width: "80%" }}>
                    <Box>
                        <Products
                            products={products}
                            isLoading={loading}
                            replacementProducts={replacementProds?.replacementProducts}
                            searchedId={allSearchQueriesCombined?.texts?.[0]}
                            isDeleted={replacementProds?.deleted}
                            altNameList={altNameList}
                        />
                        <PageHandler type="pagePagination" itemCount={productTotalCount} />
                    </Box>
                </Box>

            </Box>
        </main>
    )
}

export default FilterProducts
