import MobileLoading from './loadingComponents/MobileLoading'
import DesktopLoading from './loadingComponents/DesktopLoading'
import { productViewBreakpoint } from '../../../../lib/helper/viewportHelper'
import theme from '../../../../theme'
import { useMediaQuery } from '@mui/material'
import React from 'react'

const LoadingView = () => {
    const isSmall = useMediaQuery(theme.breakpoints.down(productViewBreakpoint))
    return (
        isSmall ? <MobileLoading /> : <DesktopLoading />
    )
}

export default LoadingView
