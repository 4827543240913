/* eslint-disable react/jsx-filename-extension */
import useStyles from "./styles"
import { strings as Localization } from "../../../../../lib/Localization"
import classnames from "classnames"
import React from "react"
import {
    Box, Card, CardContent, Typography
} from "@mui/material"

const Instruction = ({ text, images }) => {
    const classes = useStyles()

    // eslint-disable-next-line no-useless-escape
    const regexForInstructionImages = /^.*\-\d.*/
    const filteredImages = images.filter(x => regexForInstructionImages.exec(x))

    const textRegex = /\d[.].*/mg
    let match
    const texts = []

    // eslint-disable-next-line no-cond-assign
    while ((match = textRegex.exec(text))) {
        texts.push(match[0])
    }

    return (
        <>
            <Typography
                variant="h5"
                gutterBottom
                className={classes.grayText}
            >
                {Localization.howToInstall}
            </Typography>
            <Box className={classnames(
                classes.indexRootroot,
                classes.instructionImageList,
                classes.listRoot
            )}
            >
                {texts.map(
                    (paragraph, index) => (
                        <Box key={filteredImages[index]} className={classes.cardRoot}>
                            {filteredImages[index]
                                && (
                                    <img
                                        loading="lazy"
                                        // eslint-disable-next-line max-len
                                        src={`${process.env.REACT_APP_IMAGE_URL}w450/${filteredImages[index]}`}
                                        alt={filteredImages[index]?.split(".") ? `${Localization.instructions} Brodit ${filteredImages[index]?.split(".")}` : ""}
                                        className={classes.image}
                                    />
                                )}
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="body2">
                                        {paragraph}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    )
                )}
            </Box>
        </>
    )
}

export default Instruction
