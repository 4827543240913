import { ListItem } from "@mui/material"
import React, { forwardRef } from "react"
import { NavLink } from "react-router-dom"

const AppMenuItemComponent = ({
    className, link, children
}) => {
    if (!link || typeof link !== "string") {
        return (
            <ListItem
                button
                className={className}
                children={children}
            />
        )
    }

    const NavLinkComponent = forwardRef((props, ref) => (
        <NavLink to={link} {...props} ref={ref} />
    ))

    return (
        <ListItem
            button
            className={className}
            component={NavLinkComponent}
        >
            {children}
        </ListItem>
    )
}

export default AppMenuItemComponent
