import { Button, Box } from "@mui/material"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import React from "react"

function NavigationButtonWrapper({
    customComponent, positionOnTop, showNavigationOnHover, ...props
}) {
    const {
        rightSide,
        isHovering
    } = props
    const CustomComponent = customComponent

    const hideOnHover = {
        opacity: (isHovering || !showNavigationOnHover) ? 1 : 0,
        transition: "opacity 0.5s"
    }

    const absoluteStyle = (positionOnTop) ? {
        position: "absolute",
        left: (!rightSide) ? 0 : null,
        right: (rightSide) ? 0 : null,
        zIndex: 1
    } : {}

    const newProps = {
        style: {
            ...absoluteStyle,
            ...hideOnHover
        },
        ...props
    }

    return (
        (CustomComponent)
            ? <CustomComponent {...newProps} />
            : <NavigationButton {...newProps} />
    )
}

function NavigationButton({
    style, rightSide, isHovering, ...props
}) {
    return (
        <Button
            {...props}
            style={{
                ...style,
                height: "100%"
            }}
        >
            <Box
                bgcolor="text.disabled"
                style={{
                    padding: 15,
                    margin: 15,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {
                    rightSide
                        ? <KeyboardArrowRight style={{ color: "white" }} />
                        : <KeyboardArrowLeft style={{ color: "white" }} />
                }
            </Box>
        </Button>
    )
}

export { NavigationButtonWrapper }
export default NavigationButton
