/* eslint-disable no-param-reassign */
import { apiCallBegan } from "./api"
import { createSlice } from "@reduxjs/toolkit"

export const monitorSlice = createSlice({
    name: "monitor",
    initialState: {
        cartSimulation: [],
        cartSimulationLoading: false,
        loadingCustomerOrder: false,
        customerOrder: []
    },
    reducers: {
        simulateOrderRequested: state => {
            state.cartSimulationLoading = true
        },
        simulateOrderRequestReceived: (state, action) => ({
            ...state,
            cartSimulation: action.payload,
            cartSimulationLoading: false
        }),
        simulateOrderFailed: state => {
            state.cartSimulationLoading = false
        },
        resetPlacedOrder: state => ({
            ...state,
            cartSimulation: []
        }),
        fetchOrderRequestStarted: state => {
            state.loadingCustomerOrder = true
        },
        fetchOrderRequestReceived: (state, action) => ({
            ...state,
            customerOrder: action.payload,
            loadingCustomerOrder: false
        }),
        fetchOrderFailed: state => {
            state.loadingCustomerOrder = false
        }
    }
})

export default monitorSlice.reducer

const {
    simulateOrderRequested,
    simulateOrderRequestReceived,
    simulateOrderFailed,
    resetPlacedOrder,
    fetchOrderRequestStarted,
    fetchOrderRequestReceived,
    fetchOrderFailed
} = monitorSlice.actions

export const simulateOrder = (authToken, rows) => dispatch => dispatch(
    apiCallBegan({
        url: "/api/ProductInfo/SimulateOrder",
        onStart: simulateOrderRequested.type,
        onSuccess: simulateOrderRequestReceived.type,
        onError: simulateOrderFailed.type,
        method: "POST",
        data: {
            authToken: authToken,
            rows: rows
        }
    })
)

export const resetOrder = () => dispatch => dispatch(resetPlacedOrder())

export const fetchCustomerOrder = orderNumber => dispatch => dispatch(
    apiCallBegan({
        url: `/api/ProductInfo/GetCustomerOrder?orderNumber=${orderNumber}`,
        onStart: fetchOrderRequestStarted.type,
        onSuccess: fetchOrderRequestReceived.type,
        onError: fetchOrderFailed.type
    })
)
