import React from 'react';
import { Snackbar, Button } from '@mui/material';
import { strings as Localization } from '../../lib/Localization';

const ErrorMessage = ({ open, handleClose }) => {

    const handleClick = () => {
        handleClose();
    }

    const action = (
        <>
            <Button color='primary'
                size='small'
                onClick={handleClick}
                style={{color: '#000'}}>
                {Localization.close}
            </Button>
        </>
    )

    // Todo: kanske inte ska ha en sån här (Snackbar) som visar att det inträffat fel.. 

    return (
        <Snackbar 
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            open={open} 
            autoHideDuration={10000} 
            onClose={handleClose}
            message={Localization.anErrorOccuredTryRelogging}
            action={action}
            ContentProps={{style: {
                backgroundColor: '#f44336',
                color: '#fff',
            }}}
            />
    )
}

export default ErrorMessage
