import { Box, Checkbox, List, ListItem, ListItemText } from '@mui/material'
import useStyles from '../../styles'
import React, { useState } from 'react'
import ExpandableHeader from './ExpandableHeader'

const PropertiesList = ({ title, properties, handleToggle, checkIfSelected }) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    return (
        <ListItem>
            <Box flexDirection="column" display="flex">
                <ExpandableHeader open={open} setOpen={setOpen} text={title} />
                {
                    open && (
                        <List className={classes.listRoot}>
                            {properties.map((property) => {
                                const labelId = `checkbox-list-label-${title}`
                                return (
                                    <ListItem
                                        key={labelId}
                                        onClick={() => handleToggle(property)}
                                        dense
                                        button
                                        className={classes.dense}
                                        style={{ minWidth: 0, display: "flex" }}
                                    >
                                        <Checkbox
                                            edge="start"
                                            checked={checkIfSelected(property)}
                                            tabIndex={-1}
                                            disableRipple
                                            classes={{
                                                root: classes.icon,
                                                checked: classes.checked
                                            }}
                                            inputProps={{ "aria-labelledby": labelId }}
                                        />
                                        <ListItemText id={labelId} primary={property.value} />
                                    </ListItem>
                                )
                            })}
                        </List>
                    )
                }
            </Box>
        </ListItem >
    )
}

export default PropertiesList