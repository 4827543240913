/* eslint-disable no-unused-vars */
// Need to use the React-specific entry point to import createApi
import { selectDynamicPage, selectMarkdownText } from "../../lib/helper/dynamicContentHelper"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
// import { sortNews } from '../../lib/helper/newsHelper';

// Define a service using a base URL and expected endpoints
export const contentApi = createApi({
    reducerPath: "contentApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/files/" }),
    endpoints: builder => ({
        //     getProclipNews: builder.query({
        //         query: () => `news/proclip.json`,
        //         transformResponse: (response) => sortNews(response),
        //     }),
        getContent: builder.query({
            query: route => `${route}`
        }),
        getDynamicPages: builder.query({
            query: "DynamicPages/DynamicPages.json"
        }),
        getDynamicPageMarkdown: builder.query({
            query: ({ id, language }) => "DynamicPages/DynamicPages.json",
            transformResponse: (response, _meta, arg) => {
                const dynamicPage = selectDynamicPage(response, arg.id)
                const markdownText = selectMarkdownText(dynamicPage, arg.language)

                return markdownText
            }
        }),
        getDynamicPage: builder.query({
            query: ({ id }) => "DynamicPages/DynamicPages.json",
            transformResponse: (response, _meta, arg) => selectDynamicPage(response, arg.id)
        })
    })
})
// export const { useGetProclipNewsQuery } = contentApi;
export const {
    useGetContentQuery,
    useGetDynamicPagesQuery,
    useGetDynamicPageMarkdownQuery,
    useGetDynamicPageQuery
} = contentApi
