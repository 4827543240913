import { Typography } from '@mui/material';
import { strings as Localization } from '../../../../lib/Localization';
import useStyles from './styles';

export function ListProductNumber(props) {
    const classes = useStyles();
    return (
        <div>
            <Typography
                variant='h5'
                className={classes.heading}
                gutterBottom
            >
                {Localization.itemNumber}
            </Typography>
            <Typography variant='subtitle1'>
                {props.originalId}
            </Typography>
        </div>
    );
}

export function GridProductNumber(props) {
    return (
        <Typography variant='subtitle1'>
            {Localization.itemNumber} {props.originalId}
        </Typography>
    );
}
