import { desktopBreakpoint, mobileBreakpoint, mobileMenuBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    row: {
        display: "flex",
        justifyContent: "center",
        width: "80%"
    },
    fullWidth: {
        width: "100%"
    }
}))
