import useStyles from "./styles";
import { useGetOrderSystemMaintenanceQuery } from "../../redux/services/broditWebApi";
import { strings as Localization } from "../../lib/Localization";
import { Alert } from "@mui/material";
import React from "react";

const FlashMessage = () => {
    const classes = useStyles();
    const { data: isMaintenance, isFetching } = useGetOrderSystemMaintenanceQuery();

    return (
        isMaintenance ? (
            <Alert
                classes={{
                    root: classes.root
                }}
                variant="filled"
                severity="warning"
            >
                {Localization.orderSystemMaintenance}
            </Alert>
        ) : null
    )
}

export default FlashMessage
