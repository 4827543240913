import useStyles from "./styles";
import React from "react";
import PropTypes from "prop-types";
import {
    VerifiedUser, LocalShipping, Payment, Info, Business, ShoppingCart
} from "@mui/icons-material";

const RegisterRetailerStepIcon = ({ active, completed, icon }) => {
    const classes = useStyles();
    const icons = {
        1: <Info />,
        2: <Business />,
        3: <ShoppingCart />,
        4: <LocalShipping />,
        5: <Payment />,
        6: <VerifiedUser />
    };

    return (icons[String(icon)])
}

RegisterRetailerStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
};

export default RegisterRetailerStepIcon
