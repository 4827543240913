import { mobileBreakpoint } from "../../../../lib/helper/viewportHelper"
import { strings as Localization } from "../../../../lib/Localization"
import { useGetRelatedVideosQuery } from "../../../../redux/services/broditWebApi"
import YoutubeVideo from "../../../YoutubeVideo/YoutubeVideo"
import useStyles from "../styles"
import {
    Box, Typography, useMediaQuery, useTheme
} from "@mui/material"
import React from "react"

function RelatedVideos({ id }) {
    const { data, isFetching } = useGetRelatedVideosQuery(id)
    const hasVideos = data && data.length > 0
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint))
    const classes = useStyles()

    return (
        <>
            {
                isFetching
                && (
                    <Typography className={classes.grayText}>
                        {Localization.loading}
                        ...
                    </Typography>
                )
            }
            {
                !isFetching && hasVideos
                && (
                    <Box
                        id="tabRelatedVideos"
                        className={classes.relatedVideos}
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "3rem"
                        }}
                    >
                        {
                            data.map((videoUrl, i) => (
                                <YoutubeVideo
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${videoUrl}-${i}`}
                                    link={videoUrl}
                                />
                            ))
                        }
                    </Box>
                )
            }
            {
                !isFetching && !hasVideos
                && (
                    <Typography
                        variant="h5"
                        className={classes.grayText}
                    >
                        {Localization.thereAreNoVideosForThisProduct}
                    </Typography>
                )
            }
        </>
    )
}

export default RelatedVideos
