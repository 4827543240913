import useStyles from "./styles"
import ForgotPasswordForm from "./ForgotPasswordForm"
import { mailRegex, removeTrailingSlash } from "../../lib/helper/commonHelper"
import {
    sendGAEventErrorOnSignIn,
    sendGAEventMaintenanceSignIn,
    sendGAEventSignedIn,
    sendGAEventSignInUnauthorized
} from "../../lib/helper/googleAnalyticsHelper"
import { strings as Localization } from "../../lib/Localization"
import useCookiesConsent from "../../redux/hook/gdprHooks"

import { useGetOrderSystemMaintenanceQuery } from "../../redux/services/broditWebApi"
import { setSnackbarMessage } from "../../redux/userMessageSlice"
import { signIn } from "../../redux/userSlice"
import {
    Avatar,
    Button,
    CircularProgress,
    Collapse,
    Container,
    Paper,
    TextField,
    Typography,
    useTheme,
    IconButton
} from "@mui/material"
import { LockOutlined } from "@mui/icons-material"
import axios from "axios"
import classNames from "classnames"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { Navigate, useResolvedPath } from "react-router-dom"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"

const SignIn = () => {
    const defaultValues = {
        email: null,
        password: null
    }

    const [resetPasswordVisible, setResetPasswordVisible] = useState(false)
    const theme = useTheme()
    const [working, setWorking] = useState(false)
    const acceptedCookies = useCookiesConsent()
    const { data: isMaintenance } = useGetOrderSystemMaintenanceQuery()
    const [passwordHidden, setPasswordHidden] = useState(true)

    const {
        register, handleSubmit, formState: { errors }
    } = useForm({ defaultValues: defaultValues })

    const classes = useStyles()
    const dispatch = useDispatch()
    const url = useResolvedPath("").pathname

    const onSubmit = data => {
        (async () => {
            setWorking(true)
            let status = null
            let response = null
            try {
                response = await axios.post(
                    "/api/Users/Authenticate",
                    data
                )
                status = response.status
            } catch (error) {
                status = error?.response?.status
            }

            setWorking(false)

            switch (status) {
                case (200):
                    dispatch(signIn(response.data)) // user
                    sendGAEventSignedIn()
                    break
                case (401):
                    dispatch(setSnackbarMessage(Localization.checkYourLoginDetails, "error"))
                    sendGAEventSignInUnauthorized()
                    return
                case (503):
                    dispatch(setSnackbarMessage(Localization.systemMaintenanceTryAgainLater, "error"))
                    sendGAEventMaintenanceSignIn()
                    return
                default:
                    dispatch(setSnackbarMessage(Localization.unknownError, "error"))
                    sendGAEventErrorOnSignIn()
            }
        })()

        return <Navigate to={removeTrailingSlash(url)} />
    }

    return (
        <main className={classes.content}>
            <Container maxWidth="xs">
                <Paper className={classNames(
                    classes.paper,
                    classes.paperPadding,
                    classes.paperFlex
                )}
                >
                    <Avatar className={classes.avatar}>
                        <LockOutlined />
                    </Avatar>
                    <Typography variant="h6" className={classNames(classes.capitalized, classes.textAlignCenter)}>{Localization.webshopForBroditResellers}</Typography>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            required
                            id="outlined-required"
                            label={Localization.email}
                            variant="outlined"
                            style={{ marginBottom: theme.spacing(2) }}
                            className={classes.removeAutofillBg}
                            {...register("email", { required: true, validate: value => mailRegex.test(value) })}
                            error={Boolean(errors.email)}
                            fullWidth
                            disabled={isMaintenance}
                        />
                        <TextField
                            required
                            id="outlined-password-input"
                            label={Localization.password}
                            type={passwordHidden ? "password" : "text"}
                            autoComplete="current-password"
                            variant="outlined"
                            style={{ marginBottom: theme.spacing(2) }}
                            {...register("password", { required: true })}
                            error={Boolean(errors.password)}
                            fullWidth
                            disabled={isMaintenance}
                            className={classes.removeAutofillBg}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        style={{ backgroundColor: "transparent" }}
                                        tabIndex={-1}
                                        onClick={
                                            () => setPasswordHidden(!passwordHidden)
                                        }
                                    >
                                        {passwordHidden
                                            ? <VisibilityIcon />
                                            : <VisibilityOffIcon />}
                                    </IconButton>
                                ),
                                style: { background: "pink !important" }
                            }}
                        />
                        <Button
                            disabled={!acceptedCookies || working || isMaintenance}
                            type="submit"
                            color="primary"
                            variant="contained"
                            fullWidth
                        >
                            {
                                (working)
                                    ? <CircularProgress />
                                    : Localization.logIn
                            }
                        </Button>
                    </form>
                    <Button
                        id="forgotPWD"
                        color="secondary"
                        variant="contained"
                        onClick={() => setResetPasswordVisible(!resetPasswordVisible)}
                        style={{ marginBottom: theme.spacing(2), marginTop: 48 }}
                    >
                        {Localization.forgotPassword}
                        ?
                    </Button>
                    <Collapse in={resetPasswordVisible} style={{ width: "100%" }}>
                        <ForgotPasswordForm />
                    </Collapse>
                </Paper>
            </Container>
        </main>
    )
}

export default SignIn
