/* eslint-disable jsx-a11y/label-has-associated-control */
import useStyles from "./styles"
import MobHero from "./assets/mobhero.png"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { strings as Localization } from "../../lib/Localization"
import { useRecaptchaSiteKey } from "../../redux/hook/settingsHooks"
import { setSnackbarMessage } from "../../redux/userMessageSlice"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { TextField } from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import Person2Icon from "@mui/icons-material/Person2"
import ReCAPTCHA from "react-google-recaptcha"
import { useDispatch } from "react-redux"
import axios from "axios"

const ContactPage = () => {
    const dispatch = useDispatch()
    const language = useLanguage()
    const [data, setData] = React.useState(null)
    const classes = useStyles()
    const recaptchaRef = useRef()
    const siteKey = useRecaptchaSiteKey()
    const [recaptchaToken, setRecaptchaToken] = useState(null)
    // Form states
    const [fullName, setFullName] = useState("")
    const [company, setCompany] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [formValid, setFormValid] = useState(false)

    const onChangeRecaptcha = value => {
        setRecaptchaToken(value)
    }

    const onRecaptchaExpired = () => {
        setRecaptchaToken(null)
    }

    const fetchContactData = async () => {
        const response = await fetch("/files/DynamicPages/ContactPage.json")
        return response.json()
    }

    const stripHtml = text => {
        const regex = /(<([^>]+)>)/gi
        return text.replace(regex, "")
    }

    const resetForm = () => {
        setFullName("")
        setCompany("")
        setPhone("")
        setEmail("")
        setMessage("")
        recaptchaRef.current.reset()
    }

    const submitForm = e => {
        e.preventDefault()

        const formData = {
            FullName: stripHtml(fullName),
            Company: stripHtml(company),
            Phone: stripHtml(phone),
            Email: stripHtml(email),
            Message: stripHtml(message),
            Language: language
        }

        axios.post(`/api/Contact?captchaToken=${recaptchaToken}`, formData).then(response => {
            if (response.status === 200) dispatch(setSnackbarMessage(Localization.contactMailSent, "success"))
        }).catch(() => {
            dispatch(setSnackbarMessage(Localization.somethingWentWrong, "error"))
        }).then(() => {
            resetForm()
        })
    }

    const validateForm = () => {
        if (
            fullName.length === 0
            || phone.length === 0
            || email.length === 0
            || message.length === 0
            || !recaptchaToken
        ) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }

    React.useEffect(() => {
        fetchContactData().then(result => setData(result))
    }, [false])

    useEffect(() => {
        validateForm()
    }, [fullName, company, phone, email, message, recaptchaToken])

    const imagePlaceholder = placeholder => (
        <div className={classes.contactImage}>
            {placeholder
                && placeholder === "woman" ? <Person2Icon style={{ width: "200px", height: "200px", color: "white" }} /> : <PersonIcon style={{ width: "200px", height: "200px", color: "white" }} />}

        </div>
    )

    const renderContactCard = contact => (
        <div className={classes.contactCard}>
            {contact.image !== null
                ? <img alt={contact.name} loading="lazy" src={`/files/ImagesStatic/${contact.image}`} className={classes.contactImage} />
                : imagePlaceholder(contact.placeholder)}
            <div className={classes.contactInfo}>
                <div className={classes.contactTopInfo}>
                    <span className={classes.contactName}>{contact.name}</span>
                    <span className={classes.contactTitle}>{contact.roles[0].names.filter(x => x.lang === language)[0].text}</span>
                </div>
                <Link className={classes.contactLink} to={`mailto:${contact.email}`}>{contact.email}</Link>
                <span className={classes.contactLink}>{contact.phone.filter(x => x.lang === language)[0].number}</span>
            </div>
        </div>
    )

    const renderContactSection = section => (
        <div key={section.title[0].text}>
            <div>
                <h2 className={classes.title}>{section.title.filter(x => x.lang === language)[0].text}</h2>
            </div>
            <div className={classes.contactCardGrid}>
                {section.list.map(contact => renderContactCard(contact))}
            </div>
        </div>
    )

    return (
        <div className={classes.root}>
            <img className={classes.mobileHero} loading="lazy" src={MobHero} alt="Mobile hero brodit" />
            <div className={classes.contactHero}>
                <div className={classes.formWrapper}>
                    <div className={classes.contactForm}>
                        <h2 style={{ margin: 0, marginBottom: "16px" }} className={classes.title}>{Localization.contactUs}</h2>
                        <form onSubmit={submitForm}>
                            <div className={classes.formGrid}>
                                <TextField required value={fullName} onChange={x => setFullName(x.target.value)} variant="outlined" label={Localization.contactFullname} />
                                <TextField value={company} onChange={x => setCompany(x.target.value)} variant="outlined" label={Localization.contactCompany} />
                                <TextField required value={phone} onChange={x => setPhone(x.target.value)} variant="outlined" label={Localization.contactPhone} type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
                                <TextField required value={email} onChange={x => setEmail(x.target.value)} variant="outlined" label={Localization.contactEmail} type="email" />
                            </div>
                            <div className={classes.textfieldWrapper}>
                                <span className={classes.helpText}>{Localization.contactMessageTitle}</span>
                                <TextField value={message} onChange={x => setMessage(x.target.value)} multiline rows={5} />
                                <div className={classes.buttonWrapper}>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={siteKey}
                                        onExpired={onRecaptchaExpired}
                                        onChange={onChangeRecaptcha}
                                        hl={language}
                                    />
                                    <div>
                                        <button disabled={!formValid} className={classes.button} type="submit">{Localization.contactSend}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={classes.addressWrapper}>
                        {data
                            && (
                                <div className={classes.contactAddress}>
                                    <h3 className={classes.locationTitle}>{Localization.contactOfficeAndManufacturing}</h3>
                                    <div className={classes.addressGrid}>
                                        <div className={classes.half}>
                                            <span className={classes.addressRow}>{data.content.generalContactInfo.company}</span>
                                            <span className={classes.addressRow}>{data.content.generalContactInfo.street}</span>
                                            <span className={classes.addressRow}>{data.content.generalContactInfo.zipCode}</span>
                                        </div>
                                        <div className={classes.half}>
                                            <span className={classes.addressLink}>
                                                {data.content.generalContactInfo.phone.filter(x => x.lang === language)[0].number}
                                            </span>
                                            <Link to={`mailto:${data.content.generalContactInfo.email}`} className={classes.addressLink}>
                                                {data.content.generalContactInfo.email}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
            {data && data.content.sections.map(section => renderContactSection(section))}
        </div>
    )
}

export default ContactPage
