/* eslint-disable react/no-array-index-key */
/* eslint-disable no-bitwise */
import useStyles from "./styles"
import { strings as Localization } from "../../../../../lib/Localization"
import { compatibleModelText } from "../../../../../lib/helper/modelVersionsHelper"
import { useSearchQuery } from "../../../../../redux/hook/searchHooks"
import { moveWordsToTopComparer } from "../../../../../lib/helper/commonHelper"
import { Typography, Link as AnchorLink } from "@mui/material"
import React from "react"

const CompatibleModels = ({ compatibleModels, handleShowMoreCompsClick }) => {
    const classes = useStyles()
    const allSearchQueries = useSearchQuery()
    const brand = allSearchQueries.brands?.[0] ?? ""
    const model = allSearchQueries.models?.[0] ?? ""
    const year = allSearchQueries.years?.[0] ?? ""

    return (
        <ul className={classes.list}>
            {
                compatibleModels
                    ? [...compatibleModels]
                        .sort((a, b) => moveWordsToTopComparer(
                            compatibleModelText(a),
                            compatibleModelText(b),
                            [brand, model, year]
                        ))
                        .slice(0, 2)
                        .filter(x => x.brand !== ",")
                        .map((comp, i) => (
                            <li key={`${comp.brand + comp.model + comp.years}${i}`}>
                                <Typography variant="body2" color="textPrimary">
                                    {compatibleModelText(comp)}
                                    {
                                        (
                                            compatibleModels.length > 2
                                            && handleShowMoreCompsClick === null
                                            & i === 1
                                        )
                                            ? Localization.moreSmall
                                            : null
                                    }
                                </Typography>
                                {compatibleModels.length > 2 && i === 1 ? (
                                    <div>
                                        {handleShowMoreCompsClick !== null ? (
                                            <Typography gutterBottom variant="caption">
                                                <AnchorLink href="#" onClick={handleShowMoreCompsClick}>
                                                    {Localization.showMore}
                                                </AnchorLink>
                                            </Typography>
                                        ) : (null)}
                                    </div>
                                ) : null}
                            </li>
                        ))
                    : null
            }
        </ul>
    )
}

export default CompatibleModels
