import { strings as Localization } from "../../../lib/Localization";
import React, { useRef, useState } from "react"
import {
    Button, ButtonGroup, Box, Typography
} from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const CsvReader = ({ callbackFn }) => {
    const [csvFile, setCsvFile] = useState(null);
    const ref = useRef();

    const handleFormSubmit = () => {
        if (csvFile) submit();
    }

    const processCSV = (str, delim = ";") => {
        let success = true;
        let rows = str.split(/\r\n|\n|\r/);

        // Remove all empty rows.
        rows = rows.filter(x => x !== "");

        rows = rows.map(row => {
            // Extract the value for the first column as the 'article'
            // and the second column as 'quantity' for each row.
            const [article, quantity] = row.split(delim).map(val => val.trim());

            return {
                article,
                quantity,
                original: row,
                success: false
            }
        });

        const failedRows = [];
        const successfulRows = [];
        rows.forEach(row => {
            const isNumber = (
                !isNaN(parseFloat(row.quantity))
                && isFinite(row.quantity)
            );

            if (isNumber) {
                successfulRows.push(
                    {
                        ...row,
                        quantity: parseInt(row.quantity, 10),
                        success: true
                    }
                );
            } else {
                success = false;
                failedRows.push(row);
            }
        });

        return {
            success,
            successfulRows,
            failedRows
        };
    }

    const submit = () => {
        const file = csvFile;
        const reader = new FileReader();

        reader.onload = function (e) {
            const text = e.target.result;
            const delim = text.includes(";") ? ";" : ",";
            const { success, successfulRows, failedRows } = processCSV(text, delim);

            if (success) {
                const articles = successfulRows.map(
                    ({ article, quantity }) => ({ article, quantity })
                );
                callbackFn(true, articles);
            } else {
                callbackFn(false, null);
            }

            ref.current.reset();
            setCsvFile(null);
        }

        reader.readAsText(file);
    }

    return (
        <form id="csv-form" ref={ref}>
            <ButtonGroup fullWidth>
                <Button
                    variant="contained"
                    color={!csvFile ? "primary" : "secondary"}
                    component="label"
                    onClick={handleFormSubmit}
                    endIcon={<FileCopyIcon />}
                >
                    {Localization.chooseAFile}
                    <input
                        type="file"
                        accept=".csv"
                        id="csvFile"
                        onChange={e => {
                            // Bug, if same file is chosen after clear, the gui is not updated.
                            setCsvFile(e.target.files[0])
                        }}
                        hidden
                    />
                </Button>
                <Button
                    onClick={handleFormSubmit}
                    disabled={!csvFile}
                    variant="contained"
                    color={csvFile ? "primary" : "secondary"}
                    endIcon={<PublishIcon />}
                >
                    {Localization.upload}
                </Button>
                {/* <Button
                    onClick={clearFile}
                    disabled={!csvFile}>
                    {Localization.clear}
                </Button> */}
            </ButtonGroup>
            {csvFile
                && (
                    <Box style={{
                        borderRadius: 4, border: "1px solid rgba(0, 0, 0, 0.12)", padding: "5px 15px", marginTop: 10, width: "auto"
                    }}
                    >
                        <Typography variant="caption">
                            {Localization.chosenFile}
                            :
                            {" "}
                            {csvFile.name}
                        </Typography>
                    </Box>
                )}
        </form>
    )
}

export default CsvReader
