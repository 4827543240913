/* eslint-disable react/jsx-filename-extension */
import { useProduct } from "../../../../redux/hook/productHooks"
import Material from "../components/Material"
import useStyles from "../styles"
import React from "react"
import { Box } from "@mui/material"

const Materials = ({ productId }) => {
    const product = useProduct(productId)
    const list = product.materials
    const classes = useStyles()

    if (typeof list === "undefined" || list === null) return null

    return (
        <Box className={classes.flexColumn}>
            {list.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Material key={i} id={item} />
            ))}
        </Box>
    )
}

export default Materials
