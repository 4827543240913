import { strings as Localization } from "../../../lib/Localization"
import { toggleProductGridViewSetting } from "../../../redux/settingsReducer"
import { mobileBreakpoint } from "../../../lib/helper/viewportHelper"
import React from "react"
import {
    Tooltip, IconButton, Box, useMediaQuery
} from "@mui/material"
import ListIcon from "@mui/icons-material/List"
import AppsIcon from "@mui/icons-material/Apps"
import { useDispatch, useSelector } from "react-redux"

const ProductViewToggleButton = () => {
    const dispatch = useDispatch()
    const toggleView = () => {
        dispatch(toggleProductGridViewSetting())
    }
    const isGrid = useSelector(state => state.settings.productGridView)
    const isMobile = useMediaQuery(theme => theme.breakpoints.down(mobileBreakpoint))
    return (
        <Box sx={{ display: isMobile ? "none" : "block" }}>
            <Tooltip title={Localization.toggleView}>
                <IconButton
                    onClick={toggleView}
                    style={{ paddingTop: 12, paddingBottom: 12 }}
                >
                    {isGrid ? <ListIcon /> : <AppsIcon />}
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default ProductViewToggleButton
