import useStyles from "./Row.styles"
import React, { useState, useEffect, useRef } from "react"
import {
    Box, Typography, Button, useMediaQuery
} from "@mui/material"

const Row = ({ children, fullWidth, style }) => {
    const classes = useStyles()

    return (
        <Box style={style} className={`${classes.row} ${fullWidth && classes.fullWidth}`}>
            {children}
        </Box>
    )
}

export default Row
