import React from 'react'
import { Helmet } from 'react-helmet'
import { useLanguage } from '../../lib/hooks/languageHooks'

function NewsMetaDescription() {
    const language = useLanguage()

    const title = language === "sv"
        ? "Nyheter"
        : "News"
    const description = language === "sv"
        ? "Letar du hållare till din enhet? Se våra nyheter här!"
        : "Looking for a mounting solution? See our news here!"

    return (
        <Helmet htmlAttributes={{ lang: language }}>
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
        </Helmet>
    )
}

export default NewsMetaDescription