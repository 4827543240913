/* eslint-disable indent */
import useStyles from "./styles"
import { strings as Localization } from "../../../lib/Localization"
import React from "react"
import { Typography } from "@mui/material"
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from "prop-types"

const Banner = ({ type }) => {
    const classes = useStyles()

    const getData = whatType => {
        switch (whatType) {
            case "new":
                return {
                    text: Localization.newProduct,
                    color: "#d64936"
                }
            case "comingSoon":
                return {
                    text: Localization.comingSoon,
                    color: "#32a8a2"
                }
            case "bestseller":
                return {
                    text: Localization.bestseller,
                    color: "#d6c636"
                }
            case "phasingOut":
                return {
                    text: Localization.phasingOut,
                    color: "#a69d03"
                }
            case "blank":
                return {
                    text: "",
                    color: "transparent"
                }
            default:
                return {
                    text: "Unknown",
                    color: null
                }
        }
    }

    const data = getData(type)

    return (
        <div
            className={classes.rotated}
            style={{
                backgroundColor: data.color
            }}
        >
            <Typography className={classes.text}>
                {data.text}
            </Typography>
        </div>
    )
}

Banner.propTypes = {
    // eslint-disable-next-line react/require-default-props
    type: PropTypes.oneOf(["comingSoon", "new", "bestseller", "phasingOut", "blank"])
}

export default Banner
