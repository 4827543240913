import useStyles from "./styles"
import Hero from "../../assets/customizedSolutions.png"
import { strings as Localization } from "../../lib/Localization"
// eslint-disable-next-line import/no-cycle
import { useLanguage } from "../../lib/hooks/languageHooks"
import theme from "../../theme"
import { desktopBreakpoint } from "../../lib/helper/viewportHelper"
import productDev from "../../assets/prouctDev.png"
import React from "react"
import {
    Box, Typography, useMediaQuery, Card, CardContent, CardMedia
} from "@mui/material"
import classnames from "classnames"

const ProductDevelopment = () => {
    const classes = useStyles()
    const language = useLanguage()
    const isDesktop = useMediaQuery(theme.breakpoints.up(desktopBreakpoint))

    const contacts = [
        {
            name: "Peter Hermansson",
            country: "Sweden",
            phone: "0505-177 70",
            email: "peter@brodit.se",
            image: "/files/ImagesStatic/peter.jpg"
        },
        {
            name: "Fredrik Källström",
            country: "Sverige",
            phone: "0505-177 79",
            email: "fredrik@brodit.se",
            image: "/files/ImagesStatic/fredrik.jpg"
        }
    ]

    const renderImageTextSection = (title, image, textArray) => (
        <>
            <Box
                className={classes.textWrapper}
            >
                <h2 className={classes.blackH2}>
                    {title}
                </h2>
                {textArray.map((text, index) => (
                    <Typography key={index} className={classes.blackText} style={{ paddingBottom: "16px" }}>
                        {text}
                    </Typography>
                ))}
            </Box>
            <img
                loading="lazy"
                src={image}
                alt=""
                style={{ width: "100%", maxWidth: "800px", maxHeight: "400px" }}
            />
        </>
    )

    const renderContactCard = contact => (
        <Card
            className={classes.card}
        >
            <CardMedia
                component="img"
                image={contact.image}
                alt={contact.name}
                className={classes.alignCenter}
            />
            <CardContent className={classes.cardContent}>
                <Typography className={classes.cardTitle}>
                    {contact.name}
                </Typography>
                <Typography className={classes.cardText}>
                    {contact.country}
                </Typography>
                <Typography>
                    <a
                        href={`tel: ${contact.phone}`}
                        className={classnames(classes.link, classes.anchor)}
                    >
                        {contact.phone}
                    </a>
                </Typography>
                <Typography>
                    <a
                        href={`mailto: ${contact.email}`}
                        className={classnames(classes.link, classes.anchor)}
                    >
                        {contact.email}
                    </a>
                </Typography>
            </CardContent>
        </Card>
    )

    return (
        <main className={classes.main}>
            <Box className={classes.hero} id="hero" width="100%">
                <img
                    loading="lazy"
                    src={Hero}
                    alt=""
                    style={{
                        width: "100%", maxHeight: "600px", objectFit: "cover"
                    }}
                />
            </Box>
            <Box className={classes.section2}>
                <Box style={{
                    display: "flex", flexDirection: "column", alignItems: "center", width: isDesktop ? "1080px" : "100%"
                }}
                >
                    {renderImageTextSection(
                        Localization.productDevSection1Title,
                        productDev,
                        [
                            Localization.productDevSection1Text,
                            Localization.productDevSection2TextPart1 + Localization.productDevSection2TextPart2,
                            Localization.productDevSection2TextPart3
                        ]
                    )}
                </Box>
            </Box>
            <Box className={classes.section3}>
                <Box className={classes.section3Wrapper}>
                    <Typography variant="h2" className={classes.blackH2}>{Localization.contactUs}</Typography>
                    <Typography variant="caption" className={classes.blackText} style={{ maxWidth: "850px", textAlign: "center" }}>{Localization.productDevSection3Text}</Typography>
                    {language === "en" ? renderContactCard(contacts[0]) : renderContactCard(contacts[1])}
                </Box>
            </Box>
        </main>
    )
}

export default ProductDevelopment
