import NavbarMenu from "../NavbarMenu/NavbarMenu"
import { strings as Localization } from "../../../lib/Localization"
import useStyles from "../styles"
// eslint-disable-next-line import/no-cycle
import { LeftRightSteeringFilter } from "../../AutocompleteSearch"
// eslint-disable-next-line import/no-cycle
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { useIsUserAuthorized } from "../../../redux/hook/userHooks"
import AsyncSearch from "../../AsyncSearch/AsyncSearch"
import SpeedOrderNavbar from "../../SpeedOrder/SpeedOrderNavbar"
import useCookiesConsent from "../../../redux/hook/gdprHooks"
import engFlag from "../../../assets/eng.svg"
import sweFlag from "../../../assets/swe.png"
import MultiSearchbar from "../../AutocompleteSearch/MultiSearchbar/MultiSearchbar"
import {
    Box, Button, Tooltip, Badge, Typography
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import classnames from "classnames"
import { ShoppingCart } from "@mui/icons-material"
import React from "react"
import { useSelector } from "react-redux"
import { Link, NavLink } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

function DesktopSearchArea({ handleOpenDrawer, drawerIsOpen }) {
    const classes = useStyles()
    const language = useLanguage()
    const otherLang = language === "sv" ? "en" : "sv"
    const isAuthorized = useIsUserAuthorized()
    const cart = useSelector(state => state.cart)
    const acceptedCookies = useCookiesConsent()

    const changeLanguage = () => window.location.pathname.replace(language, otherLang)

    const scrollWithOffset = el => {
        const yCoordinate = el.getBoundingClientRect().top + window.scrollY
        const yOffset = -100
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" })
    }
    const signedInStyles = isAuthorized && ({ paddingTop: "5px", paddingBottom: "5px" })
    return (
        <Box style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "10px",
            ...signedInStyles
        }}
        >
            <Box>
                {
                    isAuthorized
                    && (
                        <Box className={classes.loggedIn}>
                            <Box>
                                <SpeedOrderNavbar />
                            </Box>
                            <Box className={classes.iconWrapper}>
                                <Tooltip title={Localization.cart}>
                                    <Button
                                        to={`/${language}/cart`}
                                        component={Link}
                                        aria-label="Show cart items"
                                        style={{ minWidth: 0 }}
                                        endIcon={(
                                            <Badge
                                                // eslint-disable-next-line max-len
                                                badgeContent={cart.items.length}
                                                color="primary"
                                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                                classes={{
                                                    // eslint-disable-next-line max-len
                                                    anchorOriginBottomRightRectangular: classes.badgePos,
                                                    badge: classes.badge
                                                }}
                                            >
                                                <ShoppingCart style={{ color: "#2d2d2d" }} />
                                            </Badge>
                                        )}
                                    />
                                </Tooltip>
                                <Tooltip title={Localization.yourFavorites}>
                                    <Button
                                        to={`/${language}/favorites`}
                                        component={Link}
                                        aria-label="Show cart items"
                                        style={{ minWidth: 0 }}
                                        endIcon={(
                                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                                                <path d="M18.9317 0C16.6599 0 14.4795 1.10354 13.0563 2.84741C11.6332 1.10354 9.45277 0 7.18097 0C3.15963 0 0 3.297 0 7.49319C0 12.6431 4.43915 16.8392 11.1631 23.2153L13.0563 25L14.9495 23.2016C21.6735 16.8392 26.1126 12.6431 26.1126 7.49319C26.1126 3.297 22.953 0 18.9317 0ZM13.1869 21.1853L13.0563 21.3215L12.9257 21.1853C6.71094 15.3133 2.61126 11.4305 2.61126 7.49319C2.61126 4.76839 4.56971 2.7248 7.18097 2.7248C9.19164 2.7248 11.1501 4.07357 11.8421 5.94005H14.2836C14.9625 4.07357 16.921 2.7248 18.9317 2.7248C21.5429 2.7248 23.5014 4.76839 23.5014 7.49319C23.5014 11.4305 19.4017 15.3133 13.1869 21.1853Z" fill="#2D2D2D" />
                                            </svg>
                                        )}
                                    />
                                </Tooltip>
                                <Box>
                                    <NavbarMenu />
                                </Box>
                            </Box>
                        </Box>
                    )
                }
            </Box>
            <Box className={classes.desktopSearchAreaBox}>
                <Box style={{ display: "flex", alignItems: "center", gap: 29 }}>
                    {!isAuthorized
                        && (
                            <NavLink to={`/${language}/static/findRetailers`} style={{ textDecoration: "none" }} className={classes.hoverUnderline} end>
                                <Typography className={classes.topBoxText}>
                                    {Localization.findReseller}
                                </Typography>
                            </NavLink>
                        )}
                    <HashLink smooth scroll={el => scrollWithOffset(el)} to={`/${language}/#news`} style={{ textDecoration: "none" }} className={classes.hoverUnderline}>
                        <Typography className={classes.topBoxText}>
                            {Localization.news}
                        </Typography>
                    </HashLink>
                    <Box className={classes.menuButton}>
                        <button
                            type="button"
                            aria-controls="product-menu"
                            aria-haspopup="true"
                            onClick={handleOpenDrawer}
                            className={classes.productButton}
                            id="productButtonDesktop"
                        >
                            {Localization.allProducts}
                            <ExpandMoreIcon className={classnames(
                                classes.icon,
                                drawerIsOpen && classes.openTransform
                            )}
                            />
                        </button>
                    </Box>
                    {!isAuthorized
                        && (
                            <Button
                                id="login"
                                component={Link}
                                style={{ backgroundColor: !acceptedCookies ? "lightgray" : "" }}
                                className={classes.loginButton}
                                to={`/${language}/signin`}
                                aria-label="Log in"
                                disabled={!acceptedCookies}
                            >
                                {Localization.logIn}
                            </Button>
                        )}
                </Box>
                <Box>
                    <Tooltip title={Localization.toggleLanguage}>
                        <Button
                            id="languageBtn"
                            component={Link}
                            to={changeLanguage()}
                            aria-label="Toggle language"
                            style={{ height: 40 }}
                        >
                            <img
                                loading="lazy"
                                src={otherLang === "en" ? engFlag : sweFlag}
                                alt={otherLang === "en" ? "us flag" : "swe flag"}
                                className={classes.flag}
                            />
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            <Box>
                <Box className={classes.searchArea}>
                    <MultiSearchbar vehicles />
                    <MultiSearchbar />
                    <Box className={classes.flexRow}>
                        <Box className={classes.flexRow}>
                            {
                                (language === "en")
                                && <LeftRightSteeringFilter />
                            }
                        </Box>
                        <AsyncSearch
                            className={classes.asyncSearch}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default DesktopSearchArea
