import { productViewBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

const tabHeight = 40

export default makeStyles(theme => ({
    tabRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: "25px"
    },
    tabs: {
        backgroundColor: "#fff",
        color: "rgba(0, 0, 0, 0.70)",
        borderBottom: "1px solid #d1d1d1",
        minHeight: tabHeight,
        height: tabHeight,
        "& .Mui-selected": {
            color: "rgba(0, 0, 0, 1)",
            backgroundColor: "#f0f0f0"
        }
    },
    mobileTabs: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        color: "rgba(0, 0, 0, 0.70)",
        height: "100%",
        overflow: "visible",
        width: "100%"
    },
    tab: {
        textTransform: "none",
        minHeight: tabHeight,
        height: tabHeight
    },
    firstTab: {
        textTransform: "none",
        minHeight: tabHeight,
        height: tabHeight
    },
    column: {
        width: 580,
        display: "block",
        float: "left",
        paddingLeft: "25px",
        color: "#666",
        [theme.breakpoints.down(productViewBreakpoint)]: {
            width: "auto",
            paddingLeft: "0px",
            float: "none"
        }
    },
    grayText: {
        color: "#666"
    },
    appBar: {
        boxShadow: "none"
    },
    content: {
        flex: "0 1 1920px",
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    mobileContent: {
        flexGrow: 1,
        padding: 0
    },
    flexRow: {
        display: "flex",
        flexDirection: "row"
    },
    sideBoard: {
        width: 200,
        backgroundColor: "#f3f3f3"
    },
    contentSkeleton: {
        width: 580,
        display: "block",
        float: "left",
        paddingLeft: "25px",
        height: 580,
        margin: 50
    },
    link: {
        textDecoration: "none",
        color: "#666",
        "&:hover": {
            textDecoration: "underline"
        },
        "&:visited": {
            color: "#666"
        }
    },
    productMain: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "row",
        width: "100%"
    },
    mobileProductMain: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    mobileProductCard: {
        backgroundColor: "#f0f0f0"
    },
    panelBox: {
        minHeight: "400px",
        [theme.breakpoints.down(productViewBreakpoint)]: {
            minHeight: "auto"
        }
    },
    halfWidth: {
        width: "50%"
    },
    justifyCenter: {
        display: "flex",
        justifyContent: "center"
    },
    bigLoading: {
        width: 400,
        height: 450
    },
    smallLoading: {
        width: 160,
        height: 40
    },
    instructionRoot: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    instructionHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    instructionImageList: {
        width: "min-content"
    },
    tableRoot: {
        marginBottom: theme.spacing(4),
        maxWidth: 580,
        marginLeft: theme.spacing(4),
        "& tr": {
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            "& > td": {
                width: "50%"
            }
        },
        [theme.breakpoints.down(productViewBreakpoint)]: {
            marginLeft: "0px"
        }
    },
    gray: {
        color: "rgba(0, 0, 0, 0.54)"
    },
    compatibilityList: {
        padding: 0,
        paddingLeft: "1em",
        margin: 0
    },
    compatibilityItem: {
        marginBottom: theme.spacing(0.5)
    },
    documentLink: {
        color: "#666",
        textDecoration: "none",
        [theme.breakpoints.down(productViewBreakpoint)]: {
            textDecoration: "underline",
            color: "#666"
        }
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    relatedVideos: {
        flex: "0 0 100%"
    },
    productInfoWrapper: {
        display: "flex",
        justifyContent: "center"
    }
}))
