import { desktopBreakpoint, mobileBreakpoint, mobileMenuBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    timeline: {
        flex: "0 0 100%",
        display: "flex",
        justifyContent: "center",
        gap: 30,
        minHeight: 50
    },
    oddTimeline: {
        flexDirection: "row-reverse"
    },
    timelineInfo: {
        flex: "0 1 560px",
        paddingRight: 10,
        paddingLeft: 10
    },
    timelineDivider: {
        display: "flex",
        justifyContent: "center",
        position: "relative",
        marginRight: 30,
        marginLeft: 30
    },
    timelineDividerVerticalLine: {
        width: 4,
        backgroundColor: "#2D2D2D",
        height: "100%"
    },
    timelineDividerHorizontalLine: {
        position: "absolute",
        height: 4,
        left: 0,
        top: 23,
        width: 30,
        backgroundColor: "#2D2D2D"
    },
    oddTimelineDividerHorizontalLine: {
        left: "unset",
        right: 0
    },
    timelineImage: {
        maxWidth: "100%",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 0 100%"
        }
    },
    yearTitle: {
        fontFamily: "Rubik",
        fontSize: 30,
        fontWeight: 500,
        lineHeight: 0,
        textAlign: "left",
        color: "#F09057",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            fontSize: 20
        }
    },
    text: {
        fontFamily: "Arial",
        fontSize: 17,
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: 0.5,
        textAlign: "left",
        whiteSpace: "pre-line",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            fontSize: 14,
            lineHeight: "20px"
        }
    },
    textJustified: {
        textAlign: "justify"
    },
    oddText: {
        textAlign: "right"
    },
    arrow: {
        marginTop: 30,
        marginBottom: 30,
        opacity: 0.5
    },
    mobInfoWrapper: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 22,
        paddingRight: 22,
        gap: 20,
        backgroundColor: "#F8F8F8",
        "&:last-child": {
            paddingBottom: 40
        }
    },
    mobInfoReverse: {
        flexDirection: "column-reverse"
    },
    mobImageWrapper: {
        display: "flex",
        flexWrap: "wrap"
    },
    mobImageText: {
        fontFamily: "Arial",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "15px",
        letterSpacing: 0.5,
        textAlign: "left",
        whiteSpace: "pre-line",
        marginTop: 6
    }
}))
