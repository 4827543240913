import useStyles from "./styles"
import { strings as Localization } from "../../../../lib/Localization"
import { emptyCart } from "../../../../redux/cartSlice"
import { useUser } from "../../../../redux/hook/userHooks"
import ConfirmationDialog from "../../OrderSummary/ConfirmationDialog/ConfirmationDialog"
import {
    Box, IconButton, Tooltip, Typography
} from "@mui/material"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import classnames from "classnames"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

const HeadingRow = ({ showRestItemsHeading, isOrderConfirmation }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const user = useUser()

    const [dialogOpen, setDialogOpen] = useState(false)

    const openModal = () => {
        setDialogOpen(true)
    }

    const closeModal = () => {
        setDialogOpen(false)
    }

    const empty = () => {
        dispatch(emptyCart())
        setDialogOpen(false)
    }

    return (
        <Box className={classnames(classes.flexRow, classes.root)}>
            <ConfirmationDialog
                open={dialogOpen}
                onClose={closeModal}
                onConfirm={empty}
            />
            <Box className={classes.flexItem} />
            <Box className={classes.flexItem}>
                <Typography variant="h6" className={classnames(classes.capitalize, classes.gray)}>
                    {Localization.itemNumber}
                </Typography>
            </Box>
            <Box className={classnames(classes.flexItem, classes.basis20)}>
                <Typography variant="h6" className={classnames(classes.capitalize, classes.gray)}>
                    {Localization.product}
                </Typography>
            </Box>
            <Box className={classes.flexItem}>
                <Typography variant="h6" className={classnames(classes.capitalize, classes.gray)}>
                    {Localization.note}
                </Typography>
            </Box>
            <Box className={classes.flexItem}>
                <Typography variant="h6" className={classnames(classes.capitalize, classes.gray)}>
                    {Localization.qty}
                </Typography>
            </Box>
            {showRestItemsHeading
                && (
                    <Box className={classes.flexItem}>
                        <Typography variant="h6" className={classnames(classes.capitalize, classes.gray)}>
                            {Localization.sentQuantity}
                        </Typography>
                    </Box>
                )}
            <Box className={classes.flexItem}>
                <Typography variant="h6" className={classnames(classes.capitalize, classes.gray)}>
                    {Localization.price}
                </Typography>
                <Typography variant="caption" className={classnames(classes.capitalize, classes.gray)}>
                    {Localization.exVat.toUpperCase()}
                </Typography>
            </Box>
            <Box className={classes.flexItem}>
                <Typography variant="h6" className={classnames(classes.capitalize, classes.gray)}>
                    {Localization.total}
                    {" "}
                    {user?.currency}
                </Typography>
                <Typography variant="caption" className={classnames(classes.capitalize, classes.gray)}>
                    {Localization.exVat.toUpperCase()}
                </Typography>
            </Box>
            {!showRestItemsHeading && !isOrderConfirmation
                ? (
                    <Box className={classes.flexItem}>
                        <Tooltip title={Localization.removeAll}>
                            <IconButton onClick={openModal} color="secondary" className={classes.padding4}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
                : <Box className={classes.flexItem} />}
        </Box>
    )
}

export default HeadingRow
