import useStyles from "./styles"
import { strings as Localization } from "../../../../lib/Localization"
import React, { useState } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import {
    Box, Typography
} from "@mui/material"

const ConfirmationDialog = ({ open, onConfirm, onClose }) => {
    const classes = useStyles()

    return (
        <Dialog open={open} onClose={onClose}>
            <Box className={classes.dialog}>
                <Typography variant="h5">{Localization.removeAll}</Typography>
                <Box className={classes.dialogTextWrapper}>
                    <Typography variant="body2">{Localization.areYouSure}</Typography>
                    <Typography variant="body2">{Localization.deleteAllConfirmation}</Typography>
                </Box>
                <Box className={classes.dialogButtonWrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.dialogButton}
                        onClick={onConfirm}
                    >
                        {Localization.yes}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.dialogButton}
                        onClick={onClose}
                    >
                        {Localization.no}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}

export default ConfirmationDialog
