import { strings as Localization } from "../../../lib/Localization"
import { setSnackbarMessage } from "../../../redux/userMessageSlice"
import { useUserAuthToken } from "../../../redux/hook/userHooks"
import { useGetOrderSystemMaintenanceQuery } from "../../../redux/services/broditWebApi"
import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import {
    FormControl,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Radio,
    Button,
    FormGroup,
    Switch,
    Collapse,
    Box,
    TextField,
    FormHelperText
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import DataUsageIcon from "@mui/icons-material/DataUsage"
import GetAppIcon from "@mui/icons-material/GetApp"
import { useDispatch } from "react-redux"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

const useStyles = makeStyles(theme => ({
    "@keyframes rotate": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    working: {
        animation: `$rotate 3000ms ${theme.transitions.easing.easeInOut} infinite`
    },
    capitalized: {
        textTransform: "uppercase"
    }
}))

const DownloadForm = () => {
    const classes = useStyles()
    const defaultValues = {
        apiKey: null,
        includePrice: false,
        language: "en",
        includeHistory: false,
        historicalData: null,
        from: null
    }

    const { data: isMaintenance } = useGetOrderSystemMaintenanceQuery()

    const yesterday = (function (d) { d.setDate(d.getDate() - 1); return d }(new Date()))

    const dispatch = useDispatch()
    const [downloading, setDownloading] = useState(false)
    const [date, setDate] = useState(yesterday) // Init with yesterday

    const authToken = useUserAuthToken()

    const {
        register, handleSubmit, watch, setValue, formState: { errors }
    } = useForm({ defaultValues: defaultValues })

    useEffect(() => {
        let d = null
        try {
            d = new Date(date).toISOString().split("T")[0]
        } catch {
            setDate(null); return
        }
        setValue("from", d)
    }, [date])

    useEffect(() => {
        setValue("apiKey", null);

        (async () => {
            let apiKey = ""
            try {
                const response = await axios.get(
                    `/api/Users/GetApiKeyFromAuthToken?token=${authToken}`
                )
                apiKey = response.data
            } catch (error) {
                console.log(error)
            } finally {
                setValue("apiKey", apiKey)
            }
        })()
    }, [])

    // Vill man ha filen på svenska, engelska eller tyska?
    // Vill man ha pris?
    // Vill man endast ha med nya saker osv (filtrera då i grejen sen. HIHI)

    const handleDownload = queryString => {
        (async () => {
            setDownloading(true)
            let statusCode = 0
            let data
            try {
                await axios.get(`/api/ProductInfo/GetProductFile?${queryString}`, {
                    responseType: "blob"
                }).then(resp => {
                    statusCode = resp.status
                    data = resp.data
                }).catch(error => {
                    if (error.response) {
                        statusCode = error.response.status
                    }
                })
            } catch {
                console.log("Error fetching file with Axios.")
            }

            switch (statusCode) {
                case 200:
                    setDownloading(false)
                    break
                case 400:
                    dispatch(setSnackbarMessage(Localization.badRequestFileDownload, "error"))
                    setDownloading(false)
                    return
                case 401:
                    dispatch(setSnackbarMessage(Localization.unAuthorizedFileDownload, "error"))
                    setDownloading(false)
                    return
                default:
                    dispatch(setSnackbarMessage(Localization.errorWhenAttemptingToDownloadFile, "error"))
                    setDownloading(false)
                    return
            }

            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "file.txt") // Filnamn. Hur får man tag på det liksom.
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
        })()
    }
    const onSubmit = data => {
        const queryString = Object.keys(data)
            .map(key => `${key}=${data[key]}`)
            .join("&")
        handleDownload(queryString)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormGroup row>
                    <FormControl>
                        <FormLabel className={classes.capitalized}>{Localization.apiKey}</FormLabel>
                        <TextField multiline {...register("apiKey", { required: true })} error={Boolean(errors.apiKey)} />
                    </FormControl>
                </FormGroup>
                <Box height={15} />
                <FormGroup row>
                    <FormControl>
                        <FormLabel className={classes.capitalized}>
                            {Localization.language}
                        </FormLabel>
                        <RadioGroup defaultValue="en">
                            <FormControlLabel value="en" {...register("language", { required: true })} control={<Radio color="primary" />} label={Localization.english} />
                            <FormControlLabel value="sv" {...register("language", { required: true })} control={<Radio color="primary" />} label={Localization.swedish} />
                            <FormControlLabel value="de" {...register("language", { required: true })} control={<Radio color="primary" />} label={Localization.german} />
                        </RadioGroup>
                    </FormControl>
                </FormGroup>
                <Box height={15} />
                <FormGroup row>
                    <FormControlLabel
                        control={(
                            <Switch
                                disabled={isMaintenance}
                                color="primary"
                                {...register("includePrice")}
                            />
                        )}
                        label={Localization.includePrices}
                    />
                </FormGroup>
                <FormGroup row>
                    <FormControlLabel
                        control={(
                            <Switch
                                color="primary"
                                {...register("includeHistory")}
                            />
                        )}
                        label={Localization.updatesOnly}
                    />
                </FormGroup>
                <Collapse in={watch("includeHistory")}>
                    <Box height={15} />
                    <FormGroup row error={errors.historicalData}>
                        <FormControl>
                            <FormLabel className={classes.capitalized}>
                                {Localization.type}
                            </FormLabel>
                            <RadioGroup>
                                <FormControlLabel value="new" {...register("historicalData", { required: watch("includeHistory") })} control={<Radio color="primary" />} label={Localization.newItems} />
                                <FormControlLabel value="changed" {...register("historicalData", { required: watch("includeHistory") })} control={<Radio color="primary" />} label={Localization.changedItems} />
                                <FormControlLabel value="deleted" {...register("historicalData", { required: watch("includeHistory") })} control={<Radio color="primary" />} label={Localization.deletedItems} />
                            </RadioGroup>
                            <FormHelperText>
                                {errors.historicalData && Localization.youMustChooseAType}
                            </FormHelperText>
                        </FormControl>
                    </FormGroup>
                    <FormGroup row>
                        <FormControl>
                            <FormLabel className={classes.capitalized}>
                                {Localization.fromDate}
                            </FormLabel>
                            <TextField
                                id="date-since-historical-data"
                                type="date"
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={d => {
                                    setDate(d.target.value)
                                }}
                                value={date || ""}
                            />
                        </FormControl>
                    </FormGroup>
                </Collapse>
                <Box height={15} />
                <FormGroup row>
                    <Button
                        type="submit"
                        disabled={downloading}
                        endIcon={downloading
                            ? <DataUsageIcon className={classes.working} />
                            : <GetAppIcon />}
                        color="primary"
                        variant="contained"
                    >
                        {Localization.download}
                    </Button>
                </FormGroup>
            </LocalizationProvider>
        </form>
    )
}

export default DownloadForm
