/* eslint-disable react/jsx-filename-extension */
import useStyles from "./styles"
import { Typography } from "@mui/material"
import React from "react"
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactHtmlParser from "react-html-parser"

export default function NewlineText({ text, technicalInformation }) {
    const classes = useStyles()

    if (typeof text === "undefined" || text === null) return null
    const newText = text.split(/\r?\n/).map((str, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={i} variant="body2" color="textSecondary" component="p" className={classes.text}>
            {str}
        </Typography>
    ))

    return (
        technicalInformation === true ? (
            <div>
                {ReactHtmlParser(text)}
            </div>
        ) : <div>{newText}</div>
    )
}
