import useStyles from "./styles"
import { getDeviceBrandsList, getVehicleBrandsList } from "./helpers/brandhelper"
import Hero from "../../assets/proclipAndHolders.png"
import CarMount from "../../assets/carMount.png"
import { strings as Localization } from "../../lib/Localization"
import proClip from "../../assets/proclip.png"
import proClipHolder from "../../assets/proclipHolder.png"
// eslint-disable-next-line import/no-cycle
import { LeftRightSteeringFilter } from "../AutocompleteSearch"
import { useLanguage } from "../../lib/hooks/languageHooks"
import Holder from "../../assets/holder.png"
import theme from "../../theme"
import { desktopBreakpoint } from "../../lib/helper/viewportHelper"
import MultiSearchbar from "../AutocompleteSearch/MultiSearchbar/MultiSearchbar"
import React from "react"
import {
    Box, Typography, useMediaQuery
} from "@mui/material"
import { Link } from "react-router-dom"

const ProclipAndHolders = () => {
    const classes = useStyles()
    const language = useLanguage()
    const isDesktop = useMediaQuery(theme.breakpoints.up(desktopBreakpoint))

    const proclipBrandsList = getVehicleBrandsList()
    const deviceBrandsList = getDeviceBrandsList()

    return (
        <main className={classes.main}>
            <Box id="hero" className={classes.hero}>
                <img
                    loading="lazy"
                    src={Hero}
                    alt=""
                    style={{
                        width: "100%", height: isDesktop ? "600px" : "250px", objectFit: "cover"
                    }}
                />
            </Box>
            <Box className={classes.section1}>
                <Box className={classes.section1Wrapper}>
                    <Box maxWidth="710px" paddingLeft={!isDesktop ? "10%" : 0} paddingRight={!isDesktop ? "10%" : 0}>
                        <Typography
                            className={classes.firstBlackH2}
                            variant="h2"
                        >
                            {Localization.proclipSection1Title}
                        </Typography>
                        <Typography className={classes.firstBlackText}>
                            {Localization.proclipSection1Text}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.section2}>
                <img
                    loading="lazy"
                    src={proClip}
                    alt=""
                    className={classes.image}
                />
                <Box className={classes.box}>
                    <Box className={classes.section2ProclipWrapper}>
                        <Box maxWidth="700px">
                            <Typography variant="h2" className={classes.blackH2}>ProClip</Typography>
                            <Typography className={classes.blackText}>
                                {Localization.proclipSection2ProclipText}
                            </Typography>
                        </Box>
                        <Box style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "20px",
                            width: isDesktop ? "80%" : "100%"
                        }}
                        >
                            <Box style={{
                                display: "flex",
                                flexDirection: isDesktop ? "column-reverse" : "column",
                                alignItems: "center",
                                gap: "20px"
                            }}
                            >
                                <Typography variant="h3" className={classes.blackH3}>
                                    {Localization.findRightProclip}
                                </Typography>
                                <img loading="lazy" src={CarMount} alt="Phone holder part" />
                            </Box>
                            <div className={classes.selectorWrapper}>
                                {language === "en" && <LeftRightSteeringFilter />}
                                <MultiSearchbar snap="left" vehicles />
                            </div>
                        </Box>
                    </Box>
                </Box>
                <Box style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#F8F8F8",
                    alignItems: "center",
                    width: isDesktop ? "1080px" : "100%",
                    maxWidth: isDesktop ? "100%" : "750px",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    gap: "8px"
                }}
                >
                    <Typography variant="h3" color="initial" className={classes.brandListTitel}>{Localization.proclipBrandsTitle}</Typography>
                    <Box style={{
                        display: "flex", flexWrap: "wrap", padding: "0px 16px", justifyContent: "center", maxWidth: "80%"
                    }}
                    >
                        {proclipBrandsList.map((brand, i) => {
                            const divider = i !== proclipBrandsList.length - 1 ? " | " : ""
                            const term = brand.term ? brand.term : brand.name

                            return (
                                <Typography key={brand} variant="caption" color="initial" className={classes.brandListText}>
                                    <Link className={classes.brandLink} to={`/${language}/filter/brand/${term}`}>{brand.name}</Link>
                                    {divider}
                                </Typography>
                            )
                        })}
                    </Box>
                </Box>
                <img
                    loading="lazy"
                    src={proClipHolder}
                    alt=""
                    className={classes.image}
                />
                <Box className={classes.box}>
                    <Box className={classes.section2ProclipWrapper}>
                        <Box maxWidth="700px">
                            <Typography variant="h2" className={classes.blackH2}>
                                {Localization.proclipSection2HolderTitle}
                            </Typography>
                            <Typography className={classes.blackText}>
                                {Localization.proclipSection2HolderText}
                            </Typography>
                        </Box>
                        <Box width="100%" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Box style={{
                                display: "flex",
                                flexDirection: isDesktop ? "column-reverse" : "column",
                                alignItems: "center",
                                gap: "20px"
                            }}
                            >
                                <Typography variant="h3" className={classes.blackH3}>
                                    {Localization.findRightHolder}
                                </Typography>
                                <img loading="lazy" src={Holder} alt="Phone holder part" />
                            </Box>
                            <div className={classes.selectorWrapper}>
                                <MultiSearchbar snap="left" />
                            </div>
                        </Box>
                    </Box>
                </Box>
                <Box style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#F8F8F8",
                    alignItems: "center",
                    width: isDesktop ? "1080px" : "100%",
                    maxWidth: isDesktop ? "100%" : "700px",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    gap: "8px"
                }}
                >
                    <Typography variant="h3" color="initial" className={classes.brandListTitel}>{Localization.deviceBrandsTitle}</Typography>
                    <Box style={{
                        display: "flex", flexWrap: "wrap", padding: "0px 16px", justifyContent: "center", maxWidth: "80%"
                    }}
                    >
                        {deviceBrandsList.map((brand, i) => {
                            const divider = i !== deviceBrandsList.length - 1 ? " | " : ""
                            const term = brand.term ? brand.term : brand.name

                            return (
                                <Typography key={brand} variant="caption" color="initial" className={classes.brandListText}>
                                    <Link className={classes.brandLink} to={`/${language}/filter/brand/${term}`}>{brand.name}</Link>
                                    {divider}
                                </Typography>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        </main>
    )
}

export default ProclipAndHolders
