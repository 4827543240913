/* eslint-disable no-unused-vars */
import useStyles from "./styles"
import { checkMonitorArticles } from "../../lib/helper/userHelper"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { strings as Localization } from "../../lib/Localization"
import { addToCart } from "../../redux/cartSlice"
import { setSnackbarMessage } from "../../redux/userMessageSlice"
import { desktopBreakpoint } from "../../lib/helper/viewportHelper"
import {
    Box, Button, InputAdornment, TextField, Tooltip, Typography, useMediaQuery
} from "@mui/material"
import React, { useRef, useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"

const SpeedOrderNavbar = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const lang = useLanguage()
    const ref = useRef()
    const [quantity, setQuantity] = useState(null)
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up(desktopBreakpoint))

    const defaultValues = {
        articleNumber: null,
        quantity: null
    }

    const {
        register, handleSubmit, reset, setValue
    } = useForm({ defaultValues: defaultValues })

    const parse = i => parseInt(i, 10)

    useEffect(() => {
        setValue("quantity", quantity)
    }, [quantity])

    const onSubmit = data => {
        checkMonitorArticles(lang, [data.articleNumber], result => {
            if (!result.includes(data.articleNumber) && data.quantity > 0) {
                const q = parse(data.quantity)
                dispatch(addToCart(data.articleNumber, q))
                setQuantity(0)
                dispatch(setSnackbarMessage(`${Localization.itemNumber} ${data.articleNumber} ${Localization.hasBeenAddedToCart}`, "success"))
                reset()
            } else if (data.quantity === 0) {
                dispatch(setSnackbarMessage(Localization.articleCouldNotBeAddedQuantityZero, "error"))
            } else {
                dispatch(setSnackbarMessage(`${Localization.itemNumber} ${data.articleNumber} ${Localization.couldNotBePlacedInCart}`, "error"))
            }
        }, setLoading)

        ref.current?.focus()
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="quick-add-navbar" style={{ display: "flex", gap: 15 }}>
            <Box style={{
                display: "flex", alignItems: "center"
            }}
            >
                <TextField
                    autoComplete="off"
                    InputProps={{
                        style: {
                            display: "flex",
                            justifyContent: "space-between"
                        },
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                style={{ height: "31px" }}
                            >
                                <Box>
                                    <button
                                        type="button"
                                        onClick={() => setQuantity(quantity > 0 ? quantity - 1 : 0)}
                                        tabIndex={-1}
                                        style={{
                                            border: "none",
                                            height: 29,
                                            backgroundColor: "transparent"
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 3" fill="none">
                                            <path d="M0.0712891 2.36602V0.633789H7.07129V2.36602H0.0712891Z" fill="white" />
                                            <path d="M0.0712891 2.36602V0.633789H7.07129V2.36602H0.0712891Z" fill="#6C6C6C" />
                                        </svg>
                                    </button>
                                    <input
                                        style={{
                                            width: 40,
                                            height: 29,
                                            border: 0,
                                            background: "transparent",
                                            textAlign: "center",
                                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                            fontSize: "16px",
                                            outline: "none"
                                        }}
                                        placeholder="0"
                                        size="small"
                                        {...register("quantity", { required: true })}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => setQuantity(quantity + 1)}
                                        tabIndex={-1}
                                        style={{
                                            border: "none",
                                            height: 29,
                                            backgroundColor: "transparent"
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                                            <path d="M3.16271 8.25384V5.13917H0.0712891V3.83752H3.16271V0.746094H4.47986V3.83752H7.57129V5.13917H4.47986V8.25384H3.16271Z" fill="white" />
                                            <path d="M3.16271 8.25384V5.13917H0.0712891V3.83752H3.16271V0.746094H4.47986V3.83752H7.57129V5.13917H4.47986V8.25384H3.16271Z" fill="#6C6C6C" />
                                        </svg>
                                    </button>
                                </Box>
                            </InputAdornment>)
                    }}
                    style={{
                        width: 200,
                        borderRadius: "4px",
                        // border: " 0.5px solid #BDBDBD",
                        background: "rgba(217, 217, 217, 0.10)",
                        color: " var(--Dark-Grey, #6C6C6C)",
                        leadingTrim: "both",
                        textEdge: "cap",
                        fontFamily: "Arial",
                        fontSize: "17px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "20px", /* 117.647% */
                        letterSpacing: " 0.25px"
                    }}
                    variant="outlined"
                    placeholder={Localization.itemNumber}
                    size="small"
                    inputRef={ref}
                    className={classes.textField}
                    {...register("articleNumber", { required: true })}
                />
            </Box>
            <Tooltip title={Localization.addToCart}>
                <Button
                    size="small"
                    variant="text"
                    type="submit"
                    disabled={loading}
                    style={{
                        display: "flex",
                        padding: "10px 12px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "4px",
                        background: `${loading ? "grey" : "#F09057"}`,
                        height: 31
                    }}
                >
                    <Typography style={{
                        color: "#2D2D2D",
                        leadingTrim: "both",
                        textRdge: "cap",
                        fontFamily: "Arial",
                        fontSize: isDesktop ? "16px" : "14px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "16px" /* 100% */
                    }}
                    >
                        {Localization.addToCart}
                    </Typography>
                </Button>
            </Tooltip>
        </form>
    )
}

export default SpeedOrderNavbar
