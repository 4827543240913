import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Box, Typography } from '@mui/material';

const StyledMarkdown = ({ style, ...remainingProps }) => {
    return (
        <Box style={style}>
            <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                components={{
                    //mapping h1 to h2 and setting Typograph from mui instead of regular header tags
                    h1: 'h2',
                    h2: ({ node, ...elementProps }) => <Typography variant="h2" style={{ marginBottom: 10 }} {...elementProps} />,
                    h3: ({ node, ...elementProps }) => <Typography variant="h3" style={{ marginBottom: 10 }} {...elementProps} />,
                    h4: ({ node, ...elementProps }) => <Typography variant="h4" style={{ marginBottom: 10 }} {...elementProps} />,
                    h5: ({ node, ...elementProps }) => <Typography variant="h5" {...elementProps} />,
                    //paragraph mapping to mui
                    p: ({ node, ...elementProps }) => <Typography style={{ padding: "5px 5px 5px 5px", textAlign: "justify" }} variant="body2" {...elementProps} />,
                    img: ({ node, ...elementProps }) => (
                        <Box style={{ width: "100%" }}>
                            <img style={{ width: "100%" }} {...elementProps} />
                        </Box>
                    ),
                }}
                {...remainingProps}
            />
        </Box>
    )
}

export default StyledMarkdown;