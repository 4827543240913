import { moveWordsToTopComparer } from "../../../../lib/helper/commonHelper"
import { compatibleModelText } from "../../../../lib/helper/modelVersionsHelper"
import { strings as Localization } from "../../../../lib/Localization"
import { useProduct } from "../../../../redux/hook/productHooks"
import { useSearchQuery } from "../../../../redux/hook/searchHooks"
import useStyles from "../styles"
import React from "react"
import { Box, Typography } from "@mui/material"

export default function CompabilityInfo({ id }) {
    const classes = useStyles()
    const product = useProduct(id)
    const isNotModelSpecific = product.compatibleModels.some(
        model => model.brand === "," || model.model === ","
    )
    const allSearchQueries = useSearchQuery()
    const brand = allSearchQueries.brands?.[0] ?? ""
    const model = allSearchQueries.models?.[0] ?? ""
    const year = allSearchQueries.years?.[0] ?? ""

    return (
        <Box className={classes.column} id="tabCompatibilityInfo">
            <Typography variant="h5" gutterBottom className={classes.grayText}>
                {
                    isNotModelSpecific
                        ? Localization.thisProductIsNotModelSpecific
                        : Localization.compabilities
                }
            </Typography>
            {
                !isNotModelSpecific
                && (
                    <ul className={classes.compatibilityList}>
                        {
                            [...product.compatibleModels]
                                .sort((a, b) => moveWordsToTopComparer(
                                    compatibleModelText(a),
                                    compatibleModelText(b),
                                    [brand, model, year]
                                ))
                                .map(item => {
                                    const key = `${item.brand}${item.model}${item.years}`
                                    return (
                                        // eslint-disable-next-line max-len
                                        <li className={classes.litem} key={key}>
                                            <Typography variant="body2" className={classes.grayText}>
                                                {compatibleModelText(item)}
                                                &nbsp;
                                                {
                                                    item.brandType === "Vehicles"
                                                    && (
                                                        <span className={classes.gray}>
                                                            (
                                                            {
                                                                ([...item.countries]
                                                                    // eslint-disable-next-line max-len
                                                                    .sort((a, b) => a.localeCompare(b))
                                                                    // eslint-disable-next-line max-len
                                                                    .map(country => country.toUpperCase())
                                                                    .join(", "))
                                                            }
                                                            )
                                                        </span>
                                                    )
                                                }
                                            </Typography>
                                        </li>
                                    )
                                })
                        }
                    </ul>
                )
            }
        </Box>
    )
}
