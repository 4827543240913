import React from 'react'
import { strings as Localization } from '../../../lib/Localization';
import { useGetReplacementProductsQuery } from '../../../redux/services/broditWebApi';
import { ProductCardTemplate } from './Components/ProductCardTemplate';

function MissingFavoriteProductCard({ productId, grid }) {

    const { data: replacementProds } = useGetReplacementProductsQuery(productId);

    const getReplacementText = () => {
        return (
            replacementProds?.deleted === true && replacementProds?.replacementProducts?.length > 0 ?
                (Localization.itWasReplacedByTheFollowingArticles + getArticleString(replacementProds.replacementProducts) + '.') :
                ''
        );
    }

    const getArticleString = (array) => {
        const str = array.map(id => `${id}, `).toString();
        return str.substr(0, str.length - 2);
    }

    const productContent = {
        "images": [`${process.env.REACT_APP_IMAGE_URL}w120/${productId}.jpg`],
        "description": `${Localization.thisProductIsNoLongerAvailable} ${getReplacementText()}`,
        "name": "",
        "originalId": `${productId}`
    }

    return (
        <ProductCardTemplate
            product={productContent}
            handleShowMoreCompsClick={false}
            listView={true}
            grid={grid}
            onSelect={() => { }}
            restorationRef={null}
            compare={false}
            showShare={false}
            showCompare={false}
            showFavorite={true}
            showSpeedOrder={false}
            fillEmptySpace={true}
            displayPrice={false}
            linkToProduct={"#"}
        />
    );
}

export default MissingFavoriteProductCard;