/**
* @param {*} valueObject The searchQuery object you want to remove faulty entries from.
* Note: The object must follow the standard, that for each
* key the value must be represented as an array, for example:
* { customKeyword: ["value1", "value2", ...], anotherCustomKeyword: ["another value"], thirdCustomKeyword: [], ...}
* @returns The searchQuery object without faulty entries. Examples of faulty
* entries are keys containing empty arrays, keys containing arrays with empty strings or falsy values.
*/
export const filterFaultyValues = valueObject => Object.entries(valueObject)
    .map(
        ([key, values]) => [key, values.filter(Boolean)]
    )
    .filter(
        // eslint-disable-next-line no-unused-vars
        ([_, values]) => (values && values.length > 0)
    ).reduce(
        (result, [key, values]) => ({ ...result, [key]: values }),
        {}
    )

/**
 * Converts a URLSearchParams object into a searchQuery object.
 * @param {URLSearchParams} urlSearchParams A URLSearchParams object.
 * @returns A searchQuery object.
 */
export const extractSearchQueryFromUrlSearchParams = urlSearchParams => {
    // Convert to array.
    const urlEntries = [...urlSearchParams.entries()]

    // Convert values into arrays.
    const parsedEntries = urlEntries.map(([key, value]) => {
        // Handle "Other models" edge case.
        if (value === ",") return [key, [value]]

        return [key, value.split(",")]
    })

    const searchQuery = parsedEntries.reduce(
        (result, [key, value]) => ({ ...result, [key]: value }),
        {}
    )

    return filterFaultyValues(searchQuery)
}

/**
 * Evaluates if a searchQuery object is empty.
 * @param {*} searchQueryObject A search query object.
 * @returns Returns true if the search query object does not contain any values.
 */
export const isSearchQueryEmpty = searchQueryObject => {
    const filteredSearchQuery = filterFaultyValues(searchQueryObject)
    const flatValues = Object.values(filteredSearchQuery).flat()
    return flatValues.length <= 0
}
