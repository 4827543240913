import { strings as Localization } from "../../lib/Localization"
import { addToFavoriteProducts, removeFromFavoriteProducts } from "../../redux/favoriteSlice"
import { useIsUserAuthorized } from "../../redux/hook/userHooks"
import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IconButton, Tooltip } from "@mui/material"
import FavoriteIcon from "@mui/icons-material/Favorite"

function FavoriteButton({ productId }) {
    const dispatch = useDispatch()
    const favorites = useSelector(state => state.favorite.favoriteProducts)
    const isAuthorized = useIsUserAuthorized()
    const isFavorited = useMemo(
        () => favorites.includes(productId),
        [favorites, productId]
    )

    const toggleFavorite = () => {
        if (isFavorited) {
            dispatch(removeFromFavoriteProducts(productId))
        } else {
            dispatch(addToFavoriteProducts(productId))
        }
    }

    return (
        isAuthorized
        && (
            <Tooltip title={isFavorited ? Localization.removeFromFavorites : Localization.addToFavorites}>
                <IconButton size="small" onClick={toggleFavorite} style={{ color: isFavorited ? "#ef6c00" : null }}>
                    <FavoriteIcon />
                </IconButton>
            </Tooltip>
        )
    )
}

export default FavoriteButton
