import CheckboxList from "./CheckboxList"
import PropertiesFilter from "./PropertiesFilter"
import useStyles from "../styles"
import { strings as Localization } from "../../../../lib/Localization"
import { sortModels } from "../../../../lib/helper/modelHelper"
import { useGetModelsQuery, useGetYearsForModelQuery } from "../../../../redux/services/broditWebApi"
import { useSteering } from "../../../../redux/hook/userHooks"
import { useLanguage } from "../../../../lib/hooks/languageHooks"
import { GroupByName, sortBrands } from "../../../../lib/helper/brandHelper"
import { useSearchQuery } from "../../../../redux/hook/searchHooks"
import { setSearchQuery } from "../../../../redux/searchReducer"
import { useUrlQuery } from "../../../../lib/hooks/urlQueryHooks"
import { serializeProperty } from "../../../../lib/helper/propertyHelper"
import React, { useEffect } from "react"
import ClearIcon from "@mui/icons-material/Clear"
import {
    Box, Button, MenuItem, Select, Typography, useTheme
} from "@mui/material"
import { useDispatch } from "react-redux"

const SideFilter = ({ productsResult }) => {
    const classes = useStyles()
    const steering = useSteering()
    const language = useLanguage()
    const dispatch = useDispatch()
    const theme = useTheme()
    const urlQuery = useUrlQuery()
    const allSearchQueriesCombined = useSearchQuery()
    const deviceSearchbarSearchQuery = useSearchQuery("deviceSearchbar")
    const filterProductsSearchQuery = useSearchQuery("filterProducts")

    const allProductNames = productsResult?.names ?? []
    const allProperties = productsResult?.properties ?? []

    let { data: allModelsForBrand } = useGetModelsQuery(
        {
            language,
            steerings: [steering, "3"]
        },
        { skip: !allSearchQueriesCombined.brands?.[0] }
    )

    allModelsForBrand = (allModelsForBrand ?? [])
        .filter(
            // Hide brands with the following ids
            model => ![
                "22c5359d-7ca1-4769-b419-f5b59cdc2170"
            ].includes(model.id)
        )
    allModelsForBrand = allModelsForBrand?.filter(x => x.brand === allSearchQueriesCombined.brands?.[0])
    allModelsForBrand = sortModels(allModelsForBrand)

    const selectedProperties = allSearchQueriesCombined.properties ?? []
    const selectedProductNames = allSearchQueriesCombined.productNames ?? []
    const selectedYear = filterProductsSearchQuery.years?.[0]
        ?? allSearchQueriesCombined.years?.[0]
        ?? null

    const selectedBrands = (productsResult?.brands ?? []).filter(brand => (
        (allSearchQueriesCombined.brands ?? []).includes(brand.id)
        || (allSearchQueriesCombined.brands ?? []).includes(brand.name)
    ))

    let allBrandGroups = GroupByName(productsResult?.brands ?? [])
    allBrandGroups = sortBrands(allBrandGroups)
    const selectedGroups = GroupByName(selectedBrands)

    const selectedBrandGroups = allBrandGroups.filter(
        // If the selected group name is present in the allBrandGroups
        // and both groups have the same amount of members then return true.
        brandGroup => (
            selectedGroups.some(
                group => (
                    group.name === brandGroup.name
                    && group.value.length === brandGroup.value.length
                )
            )
        )
    )
    const selectedModels = allSearchQueriesCombined.models ?? []

    const { data: yearsForModel } = useGetYearsForModelQuery(
        {
            modelIds: selectedModels,
            brandIds: allSearchQueriesCombined.brands ?? [],
            language: language,
            steering: [steering]
        },
        { skip: selectedModels.length === 0 || allSearchQueriesCombined.brands === undefined }
    )

    const years = selectedModels.length > 0
        ? (yearsForModel ?? [])
        : []

    const updateQuery = queryPart => {
        dispatch(setSearchQuery(
            {
                type: "filterProducts",
                value: {
                    ...filterProductsSearchQuery,
                    ...queryPart
                }
            }
        ))
    }

    const setSelectedYear = year => {
        updateQuery(
            { years: (year) ? [year] : [] }
        )
    }

    const handleYearSelect = e => {
        const year = e.target.value
        setSelectedYear(year)
    }

    const addModelToQuery = model => {
        const { name } = model
        updateQuery({
            models: [
                ...filterProductsSearchQuery.models ?? [],
                name
            ]
        })
    }

    const removeModelFromQuery = model => {
        const { name } = model
        if (filterProductsSearchQuery.models) {
            updateQuery({
                models: filterProductsSearchQuery.models.filter(modelName => modelName !== name)
            })
        }
    }

    const addProductNameToQuery = productName => {
        const { id } = productName
        updateQuery({
            productNames: [
                ...filterProductsSearchQuery.productNames ?? [],
                id
            ]
        })
    }

    const removeProductNameFromQuery = productName => {
        const { id } = productName
        if (filterProductsSearchQuery.productNames) {
            updateQuery({
                productNames: filterProductsSearchQuery.productNames.filter(
                    productNameId => productNameId !== id
                )
            })
        }
    }

    const addPropertyToQuery = property => {
        const serializedProperty = serializeProperty(property)
        updateQuery({
            properties: [
                ...filterProductsSearchQuery.properties ?? [],
                serializedProperty
            ]
        })
    }

    const removePropertyFromQuery = property => {
        const serializedProperty = serializeProperty(property)
        if (filterProductsSearchQuery.properties) {
            updateQuery({
                properties: filterProductsSearchQuery.properties.filter(
                    singleProperty => singleProperty !== serializedProperty
                )
            })
        }
    }

    const addBrandToQuery = brandGroup => {
        const brandName = brandGroup.name
        updateQuery({
            brands: [
                ...filterProductsSearchQuery.brands ?? [],
                brandName
            ]
        })
    }

    const removeBrandFromQuery = brandGroup => {
        const brandGroupName = brandGroup.name
        if (filterProductsSearchQuery.brands) {
            updateQuery({
                brands: filterProductsSearchQuery.brands.filter(
                    brandName => brandGroupName !== brandName
                )
            })
        }
    }

    const clearSelection = () => {
        if (Object.entries(filterProductsSearchQuery).length > 0) {
            dispatch(
                setSearchQuery(
                    {
                        type: "filterProducts",
                        value: {}
                    }
                )
            )
        }
    }

    useEffect(() => {
        const filterProductsYears = filterProductsSearchQuery.years?.[0]
        if (filterProductsYears && !years.includes(filterProductsYears)) {
            if (!urlQuery.get("years")) { setSelectedYear(null) }
        }
    }, [years])

    return (
        <Box
            className={classes.productsContainerWithFilter}
        >
            <Box className={classes.marginBottom}>
                <CheckboxList
                    allItems={allProductNames}
                    selectedItems={selectedProductNames}
                    heading={Localization.products}
                    add={addProductNameToQuery}
                    remove={removeProductNameFromQuery}
                    showAll
                />
            </Box>
            <PropertiesFilter
                properties={allProperties}
                selectedItems={selectedProperties}
                add={addPropertyToQuery}
                remove={removePropertyFromQuery}
            />
            {allBrandGroups.length > 0
                && (
                    <Box className={classes.marginBottom}>
                        <CheckboxList
                            allItems={allBrandGroups}
                            selectedItems={selectedBrandGroups}
                            heading={Localization.brands}
                            add={addBrandToQuery}
                            remove={removeBrandFromQuery}
                        />
                    </Box>
                )}
            {
                allSearchQueriesCombined.brands?.length > 0
                && allModelsForBrand.length > 0
                && (
                    <Box className={classes.marginBottom}>
                        <CheckboxList
                            allItems={allModelsForBrand}
                            selectedItems={selectedModels}
                            heading={Localization.models}
                            add={addModelToQuery}
                            remove={removeModelFromQuery}
                        />
                    </Box>
                )
            }
            {
                allSearchQueriesCombined.models?.length > 0
                && (deviceSearchbarSearchQuery.models ?? []).length < 1
                && years.length > 0
                && (
                    <Box className={classes.marginBottom}>
                        <Typography variant="h6" className={classes.capitalized}>{Localization.year}</Typography>
                        <Select
                            onChange={handleYearSelect}
                            value={selectedYear}
                            displayEmpty
                            fullWidth
                            style={{ marginBottom: theme.spacing(3) }}
                            disabled={years.length === 0}
                            className={classes.select}
                        >
                            <MenuItem value="">{Localization.chooseYear}</MenuItem>
                            {years.map(year => (
                                <MenuItem value={year} key={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                )
            }
            <Box className={classes.marginBottom}>
                <Button
                    style={{ color: "#2d2d2d" }}
                    startIcon={<ClearIcon />}
                    onClick={() => clearSelection()}
                >
                    {Localization.deselectAll}
                </Button>
            </Box>
        </Box>
    )
}

export default SideFilter
