import React from "react"
import { Helmet } from "react-helmet"

// TODO: Byt ut texten
function ProclipMetaDescription() {
    return (
        <Helmet>
            <title>ProClip nyheter</title>
            <meta
                name="description"
                content="ProClip nyheter."
                lang="sv"
            />
            <meta
                name="description"
                content="ProClip news."
                lang="en"
            />
        </Helmet>
    )
}

export default ProclipMetaDescription
