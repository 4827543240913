/* eslint-disable max-len */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-cycle
import { SearchDealer } from "../.."
import { strings as Localization } from "../../../lib/Localization"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { useGetDynamicPageMarkdownQuery } from "../../../redux/services/contentApi"
import RegisterRetailer from "../../RegisterRetailer/RegisterRetailer"
import StyledMarkdown from "../../StyledMarkdown"
import YoutubeVideo from "../../YoutubeVideo/YoutubeVideo"
import { Typography } from "@mui/material"
import React from "react"

const DynamicContent = ({ type, ...remainingProps }) => {
    const language = useLanguage()
    const {
        data: markdownText,
        isFetching
    } = useGetDynamicPageMarkdownQuery({ id: type, language: language })

    let dynamicContentElement
    switch (type) {
        case "becomeRetailer":
            dynamicContentElement = <RegisterRetailer {...remainingProps} />
            break
        case "findRetailers":
            dynamicContentElement = <SearchDealer />
            break
        case "aboutVideo":
            dynamicContentElement = <YoutubeVideo link="https://www.youtube.com/embed/5uc-JS9ie3s?rel=0" {...remainingProps} />
            break
        case "howitworks":
            dynamicContentElement = <YoutubeVideo link="https://www.youtube.com/embed/PWSQrAEkcVw?rel=0" {...remainingProps} />
            break
        default:
            dynamicContentElement = (
                markdownText
                    ? <StyledMarkdown children={markdownText} {...remainingProps} />
                    : (
                        isFetching
                            ? <Typography>{Localization.loading}</Typography>
                            : <Typography>{Localization.noContentForTheSelectedLanguage}</Typography>
                    )
            )
    }

    return dynamicContentElement
}

export default DynamicContent
