import useStyles from "./styles"
import { strings as Localization } from "../../../lib/Localization"
import { useFilteredProducts, useProductsLoading } from "../../../redux/hook/productHooks"
// eslint-disable-next-line import/no-cycle
import Products from "../../Products/Products"
import ProductViewToggleButton from "../../Products/ProductViewToggleButton/ProductViewToggleButton"
import {
    Box, Divider, Typography, useTheme
} from "@mui/material"
import classNames from "classnames"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"

function FavoriteProductsPage() {
    const classes = useStyles()
    const favoriteProducts = useSelector(state => state.favorite.favoriteProducts)
    const filter = useMemo(() => ({ productIds: favoriteProducts }), [favoriteProducts])
    const pagination = useMemo(() => ({ offset: 0, limit: -1 }), [])
    const isLoading = useProductsLoading()
    const products = useFilteredProducts(filter, pagination)
    const missingProducts = useMemo(() => (
        favoriteProducts.filter(id => !products.some(product => product.originalId === id))
    ), [favoriteProducts, products])
    const theme = useTheme()

    return (
        <main className={classes.content}>
            <div className={classNames(classes.flexColumn, classes.wrapper)}>
                <Typography variant="h3" gutterBottom>{Localization.yourFavorites}</Typography>
            </div>
            <Box className={classes.listArea}>
                <Box style={{
                    margin: `0 ${theme.spacing(3)}px`,
                    marginTop: theme.spacing(6),
                    display: "flex",
                    justifyContent: "space-between"
                }}
                >
                    <Typography>
                        {Localization.products.toUpperCase()}
                    </Typography>
                    <ProductViewToggleButton />
                </Box>
                <Divider />
                <Products products={products} missingProducts={isLoading ? [] : missingProducts} />
            </Box>
        </main>
    )
}

export default FavoriteProductsPage
