/* eslint-disable no-param-reassign */
import { strings as Localization } from "../Localization"

export const brandsNameCompare = (brandNameA, brandNameB) => {
    // Force "Other models" to the top.
    if (brandNameA === Localization.otherModels) {
        return -1
    } if (brandNameB === Localization.otherModels) {
        return 1
    }
    return brandNameA.localeCompare(brandNameB)
}

export const sortBrands = brandList => [...brandList].sort(
    (a, b) => brandsNameCompare(a.name, b.name)
)

export const replaceNameForBrandOther = brandList => {
    // Make the result mutable
    const result = [...brandList.map(
        brand => ({ ...brand })
    )]

    // Replace the name of "," brand.
    const otherBrandIndex = result.findIndex(
        brand => brand.name === ","
    )
    if (otherBrandIndex !== -1) {
        result[otherBrandIndex].name = Localization.otherModels
    }

    return result
}

export const restoreNameForBrandOther = brandNames => {
    // Make the result mutable
    const result = [...brandNames]

    // Replace the name of "," brand.
    const otherBrandIndex = result.findIndex(
        brandName => brandName === Localization.otherModels
    )
    if (otherBrandIndex !== -1) {
        result[otherBrandIndex] = ","
    }

    return result
}

// Is used by brands, models and years.
export const GroupByName = brandList => {
    const combinedBrands = brandList.reduce(
        (result, brand) => {
            const groupBy = brand.name
            const name = brand.name
            const value = [...(result[groupBy]?.value ?? []), brand]
            result[groupBy] = { id: name, name, value }

            return result
        },
        {}
    )

    return Object.values(combinedBrands)
}

/**
 * Searches through a list of items and attempts to find all items,
 * whose specified attribute is, present in the list of identifiers.
 * @param {*} identifiers The list of identifiers.
 * @param {*} items The list of items being "filtered"/searched.
 * @param {*} attributeToCompare Specifies a function for extracting an
 * attribute from the item. Used to match nested attributes against identifiers.
 * @returns A list of two items.
 * First item: A list of the items who matched against the identifiers.
 * Second item: A list of the identifiers without a match.
 */
// Används inte just nu finns därför inte tester för denna
export const findItemsUsingIdentifier = (
    identifiers,
    items,
    attributeToCompare = (item => item)
) => {
    const noMatchIds = [...(identifiers ?? [])]
    return [
        (
            items.filter(
                item => {
                    const itemAttribute = attributeToCompare(item)
                    const hasMatch = (identifiers ?? []).includes(itemAttribute)
                    if (hasMatch) noMatchIds.splice(noMatchIds.indexOf(itemAttribute), 1)
                    return hasMatch
                }
            )
        ),
        noMatchIds
    ]
}

/**
 * Finds the name of each object with a corresponding id.
 * @param {*} brandIds The ids to find the names for.
 * @param {*} allBrands The list of objects containing an 'id' key and a 'name' key.
 * @returns A list of two items.
 * First item: A list of names corresponding to the given ids.
 * Second item: A list of ids without a corresponding name.
 */
// Används inte just nu finns därför inte tester för denna
export const findNamesFromIds = (brandIds, allBrands) => {
    const [matchedBrands, noMatchIds] = findItemsUsingIdentifier(
        brandIds,
        allBrands,
        brand => brand.id
    )

    return [
        matchedBrands.map(brand => brand.name),
        noMatchIds
    ]
}

/**
 * Finds the ids of each object with a corresponding name.
 * @param {*} brandNames The names to find the ids for.
 * @param {*} allBrands The list of objects containing an 'id' key and a 'name' key.
 * @returns A list of two items.
 * First item: A list of ids corresponding to the given names.
 * Second item: A list of names without any corresponding id.
 */
// Används inte just nu finns därför inte tester för denna
export const findIdsFromNames = (brandNames, allBrands) => {
    const brandNamesLowerCase = brandNames.map(brandName => brandName.toLowerCase())
    const [matchedBrands, noMatchNames] = findItemsUsingIdentifier(
        brandNamesLowerCase,
        allBrands,
        brand => brand.name.toLowerCase()
    )

    return [
        matchedBrands.map(brand => brand.id),
        noMatchNames
    ]
}
