import useStyles from "../../styles"
import { Box, Button, Typography } from "@mui/material"
import React from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import classnames from "classnames"

const ExpandableHeader = ({
    open, setOpen, text, showAll
}) => {
    const classes = useStyles()
    const toggleShowMore = () => setOpen(open => !open)

    return (
        <Box style={{ display: "flex" }}>
            <Typography variant="h6" className={classes.capitalized}>{text}</Typography>
            {!showAll
                && (
                    <Button
                        variant="text"
                        onClick={toggleShowMore}
                        size="small"
                        className={classnames(classes.button, classes.buttonRoot)}
                    >
                        {
                            open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                    </Button>
                )}
        </Box>
    )
}

export default ExpandableHeader
