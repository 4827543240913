import { Box, Button, Container, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography, useTheme } from '@mui/material'
import axios from 'axios'
import { useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { strings as Localization } from '../../lib/Localization'
import { mailRegex } from '../../lib/helper/commonHelper'
import { useLanguage } from '../../lib/hooks/languageHooks'
import { useRecaptchaSiteKey } from '../../redux/hook/settingsHooks'
import { setSnackbarMessage } from '../../redux/userMessageSlice'
import DynamicContent from '../DynamicContentPage/DynamicContent/DynamicContent'
import useStyles from './styles'

const NewsLetter = () => {
    const classes = useStyles()
    const language = useLanguage()
    const siteKey = useRecaptchaSiteKey()
    const recaptchaRef = useRef()
    const dispatch = useDispatch()
    const theme = useTheme()

    const defaultValues = {
        language: null,
        email: null,
        action: null,
        recaptcha: undefined
    }

    const { register, handleSubmit, watch, setValue, clearErrors, setError, formState: { errors } } = useForm({ defaultValues: defaultValues })

    useEffect(() => {
        setValue('language', language)
    }, [language])

    const onSubmit = async (data) => {
        if (data.recaptcha === undefined) {
            setError('recaptcha', {
                type: 'manual',
                message: 'Recaptcha was not activated'
            })
            return
        }

        let status = undefined
        try {
            const response = await axios.post(`/api/News/UpdateNewsLetterSubscription?token=${data.recaptcha}&action=${data.action}`, data)
            status = response.status

        } catch (exception) {
            status = exception?.response?.status
        }

        switch (status) {
            case 200:
                dispatch(setSnackbarMessage(Localization.yourSubscriptionHasBeenUpdated, 'success'))
                break
            default:
                dispatch(setSnackbarMessage(Localization.somethingWentWrong, 'error'))
                break
        }

        resetRecaptch()
    }

    const onExpired = () => {
        setValue('recaptcha', undefined)
    }

    const onChangeRecaptcha = (value) => {
        if (value) {
            clearErrors('recaptcha')
        }

        setValue('recaptcha', value)
    }

    const resetRecaptch = () => {
        recaptchaRef.current.reset()
        setValue('recaptcha', undefined)
    }

    return (
        <main className={classes.content}>
            <Container
                style={{
                    backgroundColor: theme.palette.background.default,
                    padding: theme.spacing(6),
                    paddingTop: theme.spacing(8),
                    minHeight: 600
                }}
            >
                <Typography variant='h3' className={classes.capitalized} gutterBottom>{Localization.newsLetter}</Typography>
                <DynamicContent type='newsletter' />
                <form onSubmit={handleSubmit(onSubmit)}>

                    <Box height={15} />
                    <FormGroup row>
                        <FormControl>
                            <RadioGroup defaultValue={'subscribe'}>
                                <FormControlLabel value='subscribe' {...register("action", { required: true })} control={<Radio color='primary' />} label={Localization.register} />
                                <FormControlLabel value='unsubscribe' {...register("action", { required: true })} control={<Radio color='primary' />} label={Localization.unregister} />
                            </RadioGroup>
                        </FormControl>

                    </FormGroup>
                    <Box height={25} />
                    <FormGroup row>
                        <FormControl>
                            <FormLabel className={classes.capitalized}>{Localization.email}</FormLabel>
                            <TextField {...register('email', { required: true, validate: (mail) => mailRegex.test(mail) })} error={Boolean(errors.email)} />
                        </FormControl>
                    </FormGroup>

                    <Box height={25} />
                    <FormGroup row>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={siteKey}
                            onExpired={onExpired}
                            onChange={onChangeRecaptcha}
                            hl={language}
                        />
                        {Boolean(errors.recaptcha) && <Typography color="error">{Localization.pleaseFillInThisCheckBox}</Typography>}
                    </FormGroup>

                    <Box height={15} />
                    <FormGroup row>
                        <Button type='submit' color='primary' variant='contained'>
                            {Localization.save}
                        </Button>
                    </FormGroup>
                </form>
            </Container>
        </main>
    )
}

export default NewsLetter
