import CartHandler from "./StateHandlers/CartHandler"
import FavoriteHandler from "./StateHandlers/FavoriteHandler"
import { getUserData } from "../../lib/helper/userHelper"
import { loadCookie } from "../../redux/cookieStorage"
import { acceptCookies, declineCookies } from "../../redux/gdprSlice"
import useCookiesConsent from "../../redux/hook/gdprHooks"
import { useIsUserAuthorized } from "../../redux/hook/userHooks"
import { useGetOrderSystemMaintenanceQuery } from "../../redux/services/broditWebApi"
import { signIn, signOut } from "../../redux/userSlice"
import { useDispatch } from "react-redux"
import { React, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

/**
 * Handles neccessary state changes that always need to be rendered
 * regardless if the designated component is mounted or not.
 * For example, handles default state values and clearing state when
 * changing the language.
 */
const StateHandler = () => {
    const dispatch = useDispatch()
    const isAuthorized = useIsUserAuthorized()
    const hasAllowedCookies = useCookiesConsent()
    const location = useLocation()
    const navigate = useNavigate()

    const { data: isMaintenance } = useGetOrderSystemMaintenanceQuery()

    // Logs in on mount if the authToken is present.
    useEffect(() => {
        if (isAuthorized && isMaintenance) dispatch(signOut())

        const userCookie = loadCookie("user")
        const welcomeSplashVisible = userCookie?.welcomeSplashVisible
        const authToken = userCookie?.authToken

        if (authToken) {
            getUserData(
                authToken,
                result => {
                    if (welcomeSplashVisible !== undefined) result.welcomeSplashVisible = welcomeSplashVisible
                    dispatch(signIn(result))
                    navigate(location)
                },
                () => { },
                error => {
                    dispatch(signOut())
                }
            )
        }
    }, [])

    useEffect(() => {
        // Start google analytics if gdpr is already accepted.
        if (hasAllowedCookies) {
            dispatch(acceptCookies())
        } else {
            dispatch(declineCookies())
        }
    }, [])

    return (
        <>
            <CartHandler />
            <FavoriteHandler />
        </>
    )
}

export default StateHandler
