import { declineCookies } from '../../redux/gdprSlice'
import { signOut } from '../../redux/userSlice'

// eslint-disable-next-line import/prefer-default-export, max-len
export const withdrawCookiesConsent = (skipReload = false) => (dispatch) => {
    dispatch(declineCookies())
    dispatch(signOut())

    // Couldn't find a good way to uninitialize GA4.
    // The GA4 cookie is recreated if removed.
    // Quick fix: Reloading the page prevents it from reinitializing GA4,
    // and the cookie is removed again in StateHandler.
    if (!skipReload) window.location.reload()
}
