/* eslint-disable react/jsx-filename-extension */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import useStyles from "./styles"
import theme from "./theme"
import { useLanguage } from "./lib/hooks/languageHooks"
import { strings as Localization } from "./lib/Localization"
import { clearCustomerOrderErrors } from "./redux/customerOrderReducer"
import useErrors from "./redux/hook/errorHooks"
import { useIsUserAuthorized } from "./redux/hook/userHooks"
import { saveReducers } from "./redux/localStorage"
import { clearPriceErrors } from "./redux/priceSlice"
import { store, persistor } from "./redux/store"
import ProclipAndHolders from "./components/ProclipAndHolders/ProclipAndHolders"
import ProductDevelopment from "./components/ProductDevelopment/ProductDevelopment"
import { tabletBreakpoint } from "./lib/helper/viewportHelper"
import About from "./components/About/About"
import RouteChangeTracker from "./components/RouteChangeTracker/RouteChangeTracker"
import QuickShop from "./components/SpeedOrder/QuickShop"
import WelcomeSplash from "./components/Welcomesplash/WelcomeSplash"
import ErrorPage from "./components/ErrorPage/ErrorPage"
import { useLogFrontendErrorMutation } from "./redux/services/broditWebApi"
import Career from "./components/Career/Career"
import CategoriesPage from "./components/CategoriesPage/CategoriesPage"
import ContactPage from "./components/ContactPage/ContactPage"
import { ErrorBoundary } from "react-error-boundary"
import { Helmet } from "react-helmet"
import {
    Box, StyledEngineProvider, ThemeProvider, useMediaQuery
} from "@mui/material"
import { useDispatch } from "react-redux"
import {
    BrowserRouter as Router, Route, Routes, Navigate, useLocation
} from "react-router-dom"
import React, { lazy } from "react"
import { PersistGate } from "redux-persist/integration/react"

const CompareOverview = lazy(() => import("./components/CompareOverview/CompareOverview"))
const CompareProducts = lazy(() => import("./components/CompareProducts/CompareProducts"))
const CustomerOrderInfo = lazy(() => import("./components/CustomerOrders/CustomerOrderInfo/CustomerOrderInfo"))
const CustomerOrders = lazy(() => import("./components/CustomerOrders/CustomerOrders"))
const DynamicContentPage = lazy(() => import("./components/DynamicContentPage/DynamicContentPage"))
const ErrorMessage = lazy(() => import("./components/ErrorMessage/ErrorMessage"))
const FavoriteProductsPage = lazy(() => import("./components/Favorites/FavoriteProductPage/FavoriteProductsPage"))
const FileDownload = lazy(() => import("./components/FileDownload/FileDownload"))
const FilterMetaDescription = lazy(() => import("./components/MetaData/FilterMetaDescription"))
const FilterProducts = lazy(() => import("./components/Products/FilterProducts/FilterProducts"))
const FlashMessage = lazy(() => import("./components/FlashMessage/FlashMessage"))
const Footer = lazy(() => import("./components/Footer/Footer"))
const GDPRWarning = lazy(() => import("./components/GDPRWarning/GDPRWarning"))
const Home = lazy(() => import("./components/Home/Home"))
const LandingPageMetaDescription = lazy(() => import("./components/MetaData/LandingPageMetaDescription"))
const MessageNotification = lazy(() => import("./components/MessageNotification/MessageNotification"))
const Navbar = lazy(() => import("./components/Navbar/Navbar"))
const NewsLetter = lazy(() => import("./components/NewsLetter/NewsLetter"))
const NewsListPage = lazy(() => import("./components/News/NewsListPage/NewsListPage"))
const NewsMetaDescription = lazy(() => import("./components/MetaData/NewsMetaDescription"))
const NewsPage = lazy(() => import("./components/News/NewsPage/NewsPage"))
const NotFound = lazy(() => import("./components/NotFound/NotFound"))
const Order = lazy(() => import("./components/Order/Order"))
const OrderUpload = lazy(() => import("./components/OrderUpload/OrderUpload"))
const Page = lazy(() => import("./components/Page/Page"))
const ProclipMetaDescription = lazy(() => import("./components/MetaData/ProclipMetaDescription"))
const ProductInfo = lazy(() => import("./components/Products/ProductInfo"))
const ResetPassword = lazy(() => import("./components/ResetPassword/ResetPassword"))
const SignIn = lazy(() => import("./components/SignIn/SignIn"))
const SignInMetaDescription = lazy(() => import("./components/MetaData/SignInMetaDescription"))
const SpeedOrder = lazy(() => import("./components/SpeedOrder/SpeedOrder"))
const SpeedOrderNavbar = lazy(() => import("./components/SpeedOrder/SpeedOrderNavbar"))
const StateHandler = lazy(() => import("./components/StateHandler/StateHandler"))
const User = lazy(() => import("./components/User/User"))
const VehicleNewsListPage = lazy(() => import("./components/News/VehicleNewsListPage/VehicleNewsListPage"))
const WithdrawCookiesConsent = lazy(() => import("./components/WithdrawCookiesConsent/WithdrawCookiesConsent"))

// https://dev.to/link2twenty/react-redux-and-localstorage-2lih
// https://medium.com/edonec/redux-can-be-made-easier-with-redux-toolkit-b1d2d17b90ba

const App = () => {
    const classes = useStyles()
    const language = useLanguage()
    const isAuthorized = useIsUserAuthorized()
    const errors = useErrors()
    const dispatch = useDispatch()
    const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint))
    const [logFrontendError] = useLogFrontendErrorMutation()
    const location = useLocation()

    Localization.setLanguage(language)
    store.subscribe(() => saveReducers(store.getState()))

    const handleCloseError = () => {
        dispatch(clearPriceErrors())
        dispatch(clearCustomerOrderErrors())
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorPage} onError={(error, info) => logFrontendError({ error: error.message, stack: info.componentStack })}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Navbar />
                            <div className={classes.root}>
                                <LandingPageMetaDescription />
                                <StateHandler />
                                <RouteChangeTracker />
                                <CompareOverview />
                                {/* <WelcomeSplash /> */}
                                <GDPRWarning />
                                <FlashMessage />
                                <Routes>
                                    <Route
                                        path="/"
                                        element={(
                                            <Page className={classes.homePage} title={Localization.home}>
                                                <Home />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/proclip-and-holders"
                                        element={(
                                            <Page title={Localization.proclipAndHolders}>
                                                <ProclipAndHolders />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/product-development"
                                        element={(
                                            <Page title={Localization.proclipAndHolders}>
                                                <ProductDevelopment />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/filter"
                                        element={(
                                            <Page title={Localization.searchResults}>
                                                <FilterProducts />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/collections/:collections?/:name?"
                                        element={(
                                            <Page title={Localization.searchResults}>
                                                <FilterProducts />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/filter/collection/:collections?/:name?"
                                        element={(
                                            <Page title={Localization.searchResults}>
                                                <FilterProducts />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/collection/:collections?/:name?"
                                        element={(
                                            <Page title={Localization.searchResults}>
                                                <FilterProducts />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/filter/brand/:brand?/:model?"
                                        element={(
                                            <Page title={Localization.searchResults}>
                                                <FilterProducts />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/brand/:brand/:model?"
                                        element={(
                                            <Page title={Localization.searchResults}>
                                                <FilterProducts />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/filter/brand/:brand?/:model?/*"
                                        element={(
                                            <Page title={Localization.searchResults}>
                                                <FilterProducts />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/product/:id"
                                        element={(
                                            <Page title={Localization.product}>
                                                <ProductInfo />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/cart"
                                        element={isAuthorized
                                            ? (<Page title={Localization.cart}><Order /></Page>)
                                            : (<Navigate to={`/${language}/signin`} />)}
                                    />
                                    <Route
                                        path="/signin"
                                        element={
                                            !isAuthorized
                                                ? (
                                                    <Page title={Localization.logIn}>
                                                        <SignIn />
                                                    </Page>
                                                )
                                                : <Navigate to={`/${language}/user`} />
                                        }
                                    />
                                    <Route
                                        path="/user"
                                        element={isAuthorized
                                            ? (<Page title={Localization.account}><User /></Page>)
                                            : (<Navigate to={`/${language}/signin`} />)}
                                    />
                                    <Route
                                        path="/reset-password/:guid"
                                        element={(
                                            <Page title={Localization.changePassword}>
                                                <ResetPassword />
                                            </Page>
                                        )}
                                    />
                                    <Route
                                        path="/orders"
                                        element={isAuthorized
                                            ? (<Page title={Localization.yourOrders}><CustomerOrders /></Page>)
                                            : (<Navigate to={`/${language}/signin`} />)}
                                    />
                                    <Route
                                        path="/order-upload"
                                        element={isAuthorized
                                            ? (<Page title={Localization.upload}><OrderUpload /></Page>)
                                            : (<Navigate to={`/${language}/signin`} />)}
                                    />
                                    <Route
                                        path="/favorites"
                                        element={isAuthorized
                                            ? (
                                                <Page title={Localization.yourFavorites}>
                                                    <FavoriteProductsPage />
                                                </Page>
                                            )
                                            : (<Navigate to={`/${language}/signin`} />)}
                                    />
                                    <Route path="/order/:order" element={<Page title={Localization.order}><CustomerOrderInfo /></Page>} />
                                    <Route path="/news/:place/:id" element={<Page title={Localization.news}><NewsPage /></Page>} />
                                    <Route
                                        path="/news"
                                        element={(
                                            <Page title={Localization.news}>
                                                <NewsMetaDescription />
                                                <NewsListPage />
                                            </Page>
                                        )}
                                    />
                                    <Route path="/downloads" element={<Page title={Localization.fileDownload}><FileDownload /></Page>} />
                                    <Route path="/career" element={<Page title="Lediga tjänster"><Career /></Page>} />
                                    <Route
                                        path="/proclip"
                                        element={(
                                            <Page title={Localization.news}>
                                                <VehicleNewsListPage />
                                            </Page>
                                        )}
                                    />
                                    <Route path="/newsletter" element={<Page title={Localization.newsLetter}><NewsLetter /></Page>} />
                                    <Route path="/about" element={<Page title={Localization.aboutBrodit}><About /></Page>} />
                                    <Route path="/contact" element={<Page title={Localization.aboutBrodit}><ContactPage /></Page>} />
                                    <Route path="/static/:type" element={<Page title={Localization.information}><DynamicContentPage /></Page>} />
                                    <Route path="/brodit40" element={<Page title={Localization.information}><DynamicContentPage type="brodit40" /></Page>} />
                                    <Route path="/compare" element={<Page title={Localization.compare}><CompareProducts /></Page>} />
                                    <Route path="/withdraw-consent" element={<Page title={Localization.withdrawingCookiesConsent}><WithdrawCookiesConsent /></Page>} />
                                    <Route path="*" element={<Page title={Localization.notFound}><NotFound /></Page>} />
                                    <Route
                                        path="/categories"
                                        element={(
                                            <Page title="Categories">
                                                <CategoriesPage />
                                            </Page>
                                        )}
                                    />
                                </Routes>
                                {isAuthorized && isTablet && location.pathname.match(/[^/]+$/)?.[0] !== "categories" && <QuickShop />}
                                <Footer />
                                <MessageNotification />
                                <ErrorMessage open={errors} handleClose={handleCloseError} />
                            </div>
                        </Box>
                    </PersistGate>
                </ThemeProvider>
            </StyledEngineProvider>
        </ErrorBoundary>

    )
}

export default App
