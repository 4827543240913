import useStyles from "./styles";
import React from "react";
import PropTypes from "prop-types";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { Avatar } from "@mui/material";

const OrderStepIcon = ({ active, completed, icon }) => {
    const classes = useStyles();
    const icons = {
        1: <ShoppingCartIcon />,
        2: <PersonOutlineIcon />,
        3: <ThumbUpOutlinedIcon />
    };

    return (
        <Avatar
            className={`${classes.root} ${active ? classes.active : ""} ${completed ? classes.completed : ""}`}
        >
            {icons[String(icon)]}
        </Avatar>

    // <div className={clsx(classes.root, {
    //     [classes.active]: active,
    //     [classes.completed]: completed
    // })}>
    //     {icons[String(icon)]}
    // </div>
    )
}

OrderStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
};

export default OrderStepIcon
