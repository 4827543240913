/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit"

const DEFAULT_LIMIT = 20
const DEFAULT_LIMIT_INCREASE = 20
const DEFAULT_OFFSET = 0
const DEFAULT_OFFSET_INCREASE = 10

export const pageSlice = createSlice({
    name: "page",
    initialState: {
        limit: DEFAULT_LIMIT,
        offset: 0,
        prevLimit: DEFAULT_LIMIT,
        prevOffset: 0,
        action: "none",
        userSelectedLimit: DEFAULT_LIMIT
    },
    reducers: {
        increaseLimit: state => ({
            ...state,
            prevLimit: state.limit,
            limit: state.limit + state.userSelectedLimit,
            action: "load_more"
        }),
        reset: state => ({
            ...state,
            prevLimit: state.limit,
            prevOffset: state.offset,
            limit: DEFAULT_LIMIT,
            offset: DEFAULT_OFFSET,
            userSelectedLimit: DEFAULT_LIMIT,
            action: "none"
        }),
        savePage: state => ({
            ...state,
            prevLimit: state.limit,
            prevOffset: state.offset,
            action: "leave"
        }),
        setLimit: (state, action) => ({
            ...state,
            prevLimit: state.limit,
            prevOffset: state.offset,
            limit: action.payload,
            action: "load_more"
        }),
        setOffset: (state, action) => {
            state.action = "load_more"
            state.prevOffset = state.offset
            state.offset = action.payload
        },
        setUserSelectedLimit: (state, action) => {
            state.userSelectedLimit = action.payload
        }
    }
})

export default pageSlice.reducer

const {
    increaseLimit, reset, savePage, setLimit, setUserSelectedLimit, setOffset
} = pageSlice.actions

export const saveCurrentPage = () => dispatch => dispatch(
    savePage()
)

export const increasePageLimit = () => dispatch => dispatch(
    increaseLimit()
)

export const resetCurrentPage = () => dispatch => dispatch(
    reset()
)

export const setPageLimit = limit => dispatch => dispatch(
    setLimit(limit)
)

export const setUserLimit = limit => dispatch => dispatch(
    setUserSelectedLimit(limit)
)

export const setPageOffset = offset => dispatch => dispatch(
    setOffset(offset)
)
