import PropertyGroup from "./PropertiesFilterComponents/PropertyGroup"
import { serializeProperty } from "../../../../lib/helper/propertyHelper"
import { groupBy } from "../../../../lib/helper/commonHelper"
import { Box, useTheme } from "@mui/material"
import React, { useMemo } from "react"

const PropertiesFilter = ({
    properties = [], selectedItems, add, remove
}) => {
    const theme = useTheme()
    const groups = useMemo(
        () => groupBy(properties, property => property.groupName),
        [properties]
    )

    const checkIfSelected = item => selectedItems.some(
        selected => selected === serializeProperty(item)
    )

    const handleToggle = item => {
        const exists = checkIfSelected(item)

        if (!exists) add(item)
        else remove(item)
    }

    return (
        <Box style={{ marginBottom: theme.spacing(3) }}>
            <Box flexDirection="column" display="flex">
                {Object.entries(groups).map(([groupName, propertiesPerGroup]) => (
                    <PropertyGroup
                        key={groupName}
                        groupName={groupName}
                        properties={propertiesPerGroup}
                        handleToggle={handleToggle}
                        checkIfSelected={checkIfSelected}
                    />
                ))}
            </Box>
        </Box>
    )
}

export default PropertiesFilter
