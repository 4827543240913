import CustomerOrder from "./components/CustomerOrder"
import Headers from "./components/Headers"
import useStyles from "./styles"
// eslint-disable-next-line import/no-cycle
import { PageHandler } from ".."
import { tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { strings as Localization } from "../../lib/Localization"
import { useCustomerOrders } from "../../redux/hook/customerOrderHooks"
import usePage from "../../redux/hook/pageHook"
import { useUser } from "../../redux/hook/userHooks"
import theme from "../../theme"
import React from "react"
import classnames from "classnames"
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import {
    Box, List, Typography, useMediaQuery
} from "@mui/material"

const CustomerOrders = () => {
    const classes = useStyles()
    const page = usePage()
    const customerOrders = useCustomerOrders(page.offset, page.limit)
    const isSmall = useMediaQuery(theme.breakpoints.down(tabletBreakpoint))
    const user = useUser()

    const headerList = [
        Localization.date,
        Localization.order,
        Localization.status,
        Localization.track,
        Localization.marking,
        Localization.reference,
        Localization.numberOfOrderLines,
        Localization.numberOfProducts,
        `${Localization.total} ${user?.currency}`
    ]

    return (
        <Box className={classes.content}>
            <PlaylistAddCheckIcon />
            <Typography variant="h3" gutterBottom>{Localization.yourOrders}</Typography>
            <Box className={classes.infoArea}>
                <Box className={classnames(classes.flexColumn, classes.infoText)}>
                    <CheckCircleOutlineIcon />
                    <Typography variant="caption">{Localization.checkOrderDetails}</Typography>
                </Box>
            </Box>
            <Box className={classes.listArea}>
                {!isSmall && <Headers headerList={headerList} />}
                <List>
                    {customerOrders.map((item, i) => (
                        <CustomerOrder key={i} customerOrder={item} headerList={headerList} />
                    ))}
                </List>
                <PageHandler type="button" />
            </Box>
        </Box>
    )
}

export default CustomerOrders
