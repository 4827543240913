import useStyles from "./styles"
import HolderComponent from "./components/Holder"
import Row from "./components/Row"
import HeroNews from "./components/Heronews"
import CarMount from "../../assets/carMount.png"
import MoveClip from "../../assets/MoveClip_Start.jpg"
import Suction from "../../assets/Suction_Start.jpg"
import Workstation from "../../assets/Workstation_Start.jpg"
import WorkstationMobile from "../../assets/Workstation_Mobile.jpg"
import PipeMobile from "../../assets/Pipe_Mobile.jpg"
import PedestalMobile from "../../assets/Pedestal_Mobile.jpg"
import Holder from "../../assets/holder.png"
import SolutionsMob from "../../assets/solutions_mob.jpg"
import Piedestal from "../../assets/piedestal.png"
import Ror from "../../assets/ror.png"
import Truck from "../../assets/truck.png"
import CustomSolutions from "../../assets/customizedSolutions.png"
import { strings as Localization } from "../../lib/Localization"
// eslint-disable-next-line import/no-cycle
import { LeftRightSteeringFilter } from "../AutocompleteSearch"
import { useLanguage } from "../../lib/hooks/languageHooks"
import theme from "../../theme"
import { desktopBreakpoint, mobileMenuBreakpoint, tabletBreakpoint } from "../../lib/helper/viewportHelper"
import NewsCardList from "../News/NewsCardList"
import MultiSearchbar from "../AutocompleteSearch/MultiSearchbar/MultiSearchbar"
import React from "react"
import {
    Box, Button, Typography, useMediaQuery
} from "@mui/material"
import { Link, NavLink } from "react-router-dom"
import classnames from "classnames"

const Home = () => {
    const classes = useStyles()
    const language = useLanguage()
    const isDesktop = useMediaQuery(theme.breakpoints.up(desktopBreakpoint))
    const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint))
    const showCards = useMediaQuery(theme.breakpoints.up(1170))
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileMenuBreakpoint))

    return (
        <main className={classes.main}>
            <Row fullWidth>
                <HeroNews />
            </Row>
            <Row padded>
                <Box className={classes.section1}>
                    <Box className={classes.searchWrapper}>
                        <Box className={classes.videoWrapper} id="video">
                            <iframe
                                className={classes.youtube}
                                src="https://www.youtube.com/embed/LLF6F9lD_pA?si=VtaDhxaa6b0Kx05I&rel=0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            />
                        </Box>
                        <Box id="search" className={classes.search}>
                            <Box display="flex" flexDirection="column">
                                <Typography
                                    style={{ marginBottom: 5 }}
                                    className={classnames(classes.blackH2, classes.alignTextCenter)}
                                    variant="h2"
                                >
                                    {Localization.homeSection1Titel}
                                </Typography>
                                <Typography className={
                                    classnames(classes.blackArialText, classes.alignTextCenter)
                                }
                                >
                                    {Localization.homeSection1Text}
                                </Typography>
                                <div className={classes.readmoreParent}>
                                    <NavLink to={`/${language}/proclip-and-holders`} className={classes.readmore} end>
                                        {Localization.readMore}
                                    </NavLink>
                                </div>
                                {isTablet && (
                                    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <NavLink to={`/${language}/contact`} style={{ textDecoration: "none", marginTop: "20px" }} end>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    color: "#2D2D2D",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "36px",
                                                    backgroundColor: "#F09057",
                                                    boxShadow: "none"
                                                }}
                                                className={classes.section3Button}
                                            >
                                                {Localization.contactBrodit}
                                            </Button>
                                        </NavLink>
                                    </Box>
                                )}
                            </Box>
                            {!isTablet && (
                                <Box style={{ display: "flex", alignItems: "flex-start", gap: "60px" }}>
                                    <Box className={classes.selectorWrapper}>
                                        <img loading="lazy" src={CarMount} alt="Car mount part" style={{ marginBottom: language === "en" ? "0px" : "8px" }} />
                                        <h3 className={classes.selectorTitle}>{Localization.findProClip}</h3>
                                        <div className={classes.steeringWrapper}>
                                            {language === "en" && <div className={classes.steering}>{language === "en" && <LeftRightSteeringFilter />}</div>}
                                            <MultiSearchbar vehicles maxWidth="160px" snap="center" />
                                        </div>
                                    </Box>
                                    <Box className={classes.selectorWrapper}>
                                        <img loading="lazy" src={Holder} alt="Phone holder part" style={{ marginBottom: language === "en" ? "0px" : "8px" }} />
                                        <h3 className={classes.selectorTitle}>{Localization.findDeviceHolder}</h3>
                                        <div className={classes.steeringWrapper}>
                                            {language === "en" && (
                                                <div className={classes.steering} style={{ visibility: "hidden" }}>
                                                    <LeftRightSteeringFilter />
                                                </div>
                                            )}
                                            <MultiSearchbar maxWidth="200px" snap="center" />

                                        </div>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Row>
            <Row fullWidth>
                <Box className={classes.section2Wrapper}>
                    <Box className={classes.section2}>
                        <Box className={classes.section2Text}>
                            <Box>
                                {!showCards
                                    && (
                                        <div className={classes.imageGrid}>
                                            <div className={classes.imageGridItem}>
                                                <img className={classes.imageGridItemImage} loading="lazy" src={PedestalMobile} alt="Pedestal mobile" />
                                            </div>
                                            <div className={classes.imageGridItem}>
                                                <img className={classes.imageGridItemImage} loading="lazy" src={PipeMobile} alt="Pipe mobile" />
                                            </div>
                                            <div className={classes.imageGridItem}>
                                                <img className={classes.imageGridItemImage} loading="lazy" src={WorkstationMobile} alt="Workstation mobile" />
                                            </div>
                                        </div>
                                    )}
                                <Typography className={classes.blackH2} variant="h2" style={{ textAlign: "center", marginBottom: 5 }}>
                                    {Localization.homeSection2Titel}
                                </Typography>
                                <Typography
                                    className={classes.blackArialText}
                                    style={{ textAlign: "center" }}
                                >
                                    {Localization.homeSection2Text}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                {!showCards
                                    && (
                                        <Link className={classes.anchorButtonStyle} to={`/${language}/categories`} style={{ maxWidth: "100%", textDecoration: "none" }}>
                                            {Localization.homeReadMoreAboutStrong}
                                        </Link>
                                    )}

                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Link to="https://www.brodit.se/content/231152.pdf" rel="noopener noreferrer" target="_blank" className={classes.downloadCatalog}>
                                    <Box style={{
                                        display: "flex", height: "24px", alignItems: "center", gap: "4px"
                                    }}
                                    >
                                        <Typography className={classes.blackH3Medium} variant="h3">
                                            {Localization.downloadCatalog}
                                        </Typography>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z" fill="#2D2D2D" />
                                        </svg>
                                    </Box>
                                </Link>
                            </Box>
                        </Box>
                        {showCards
                            && (
                                <Box className={classes.holders}>
                                    <HolderComponent
                                        id="piedestal"
                                        src={Piedestal}
                                        isDesktop={isDesktop}
                                        alt={Localization.homeSectionPedestalHolderTitel}
                                        title={Localization.homeSectionPedestalHolderTitel}
                                        text={Localization.homeSectionPedestalHolderText}
                                        link={{ title: Localization.homeSectionPedestalHolderLinkText, hash: "pedestal" }}
                                    />
                                    <HolderComponent
                                        id="rör"
                                        src={Ror}
                                        isDesktop={isDesktop}
                                        alt={Localization.homeSectionPipeHolderTitel}
                                        title={Localization.homeSectionPipeHolderTitel}
                                        text={Localization.homeSectionPipeHolderText}
                                        link={{ title: Localization.homeSectionPipeHolderLinkText, hash: "pipe" }}
                                    />
                                    <HolderComponent
                                        id="truck"
                                        src={Truck}
                                        isDesktop={isDesktop}
                                        alt={Localization.homeSectionTruckHolderTitel}
                                        title={Localization.homeSectionTruckHolderTitel}
                                        text={Localization.homeSectionTruckHolderText}
                                        link={{ title: Localization.homeSectionTruckHolderLinkText, hash: "forklift" }}
                                    />
                                    <HolderComponent
                                        id="suction"
                                        src={Suction}
                                        isDesktop={isDesktop}
                                        alt={Localization.homeSuctionTitle}
                                        title={Localization.homeSuctionTitle}
                                        text={Localization.homeSuctionText}
                                        link={{ title: Localization.homeSuctionLinkText, hash: "suction" }}
                                    />
                                    <HolderComponent
                                        id="screen"
                                        src={Workstation}
                                        isDesktop={isDesktop}
                                        alt={Localization.homeScreenTitle}
                                        title={Localization.homeScreenTitle}
                                        text={Localization.homeScreenText}
                                        link={{ title: Localization.homeScreenLinkText, hash: "screen" }}
                                    />
                                    <HolderComponent
                                        id="moveclip"
                                        src={MoveClip}
                                        isDesktop={isDesktop}
                                        alt={Localization.homeMoveClipTitle}
                                        title={Localization.homeMoveClipTitle}
                                        text={Localization.homeMoveClipText}
                                        link={{ title: Localization.homeMoveClipLinkText, hash: "moveclip" }}
                                    />
                                </Box>
                            )}
                        {showCards
                            && (
                                <Link className={classes.anchorButtonStyle} to={`/${language}/categories`} style={{ textDecoration: "none" }}>
                                    {Localization.homeReadMoreAboutStrong}
                                </Link>
                            )}
                    </Box>
                </Box>
            </Row>
            <Row fullWidth>
                <Box className={classes.section3}>
                    <img
                        loading="lazy"
                        src={isMobile ? SolutionsMob : CustomSolutions}
                        alt=""
                        className={classes.customSolutionImg}
                    />
                    <Box className={classes.section3Wrappper}>
                        <Box className={classes.section3TextBox}>
                            <Typography
                                className={classes.blackH2}
                                variant="h2"
                                style={{
                                    marginTop: isDesktop ? 0 : 20, textAlign: isDesktop ? "start" : "center", marginBottom: 5, flex: "0 1 100%"
                                }}
                            >
                                {Localization.homeSectionCustomSolutionsTitel}
                            </Typography>
                            <Typography className={classes.blackArialText} style={{ flex: "0 1 618px", textAlign: isDesktop ? "start" : "center" }}>
                                {Localization.homeSectionCustomSolutionsText}
                            </Typography>
                            <Box style={{
                                display: "flex", gap: "20px", marginTop: "20px", flex: "0 1 100%", justifyContent: isDesktop ? "flex-start" : "center"
                            }}
                            >
                                <Link className={classes.anchorButtonStyle} to={`/${language}/product-development`} style={{ textDecoration: "none" }}>
                                    {Localization.readMore}
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Row>
            <Row fullWidth>
                <Box className={classes.section4Wrapper}>
                    <Box className={classes.section4} id="news">
                        <Box className={classes.section4News}>
                            <Typography
                                style={{ textAlign: "center" }}
                                className={classes.blackH2}
                                variant="h2"
                            >
                                {Localization.news}
                            </Typography>
                            <Typography
                                className={classes.blackArialText}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                {Localization.homeSection4Text}
                            </Typography>
                            {
                                (language === "en" && isTablet)
                                && <LeftRightSteeringFilter mobileSearchArea />
                            }
                        </Box>
                        <Box id="newsWrapper" className={classes.newsWrapper}>
                            <NewsCardList type="device" title={Localization.deviceHolder} />
                            <NewsCardList type="vehicle" title="ProClip" />
                        </Box>
                    </Box>
                </Box>
            </Row>
        </main>
    )
}

export default Home
