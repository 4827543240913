import useStyles from "./styles"
import { strings as Localization } from "../../../lib/Localization"
import usePage from "../../../redux/hook/pageHook"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import PageHandler from "../../PageHandler/PageHandler"
import { useGetNewsQuery } from "../../../redux/services/broditWebApi"
import NewsItem from "../NewsItem"
import {
    Box, Typography, Container, Skeleton
} from "@mui/material"

import React from "react"

function NewsListPage() {
    const { limit } = usePage()
    const language = useLanguage()
    const { data, isFetching } = useGetNewsQuery({
        language,
        limit,
        groups: ["device"]
    })
    const newsArticles = data ?? []
    const classes = useStyles()
    let skeletonCount = limit - newsArticles.length
    if (skeletonCount < 1) skeletonCount = 1

    return (
        <div>
            <Container className={classes.container}>
                <Typography variant="h5" className={classes.marginTop50}>
                    {Localization.newProductsForDevices.toUpperCase()}
                </Typography>
                <div className={classes.newsGrid}>
                    {
                        newsArticles.map(news => (
                            <NewsItem
                                isDevice
                                key={news.id}
                                news={news}
                                url={`/${language}/news/${news.originalId}`}//
                                alt={`${Localization.news} ${news.originalId}`}
                            />
                        ))
                    }
                    {
                        isFetching
                        && [...Array(skeletonCount).keys()].map(i => (
                            <div key={i}>
                                <Skeleton
                                    variant="text"
                                    className={classes.textSkeleton}
                                />
                                <Skeleton variant="rect" className={classes.rectSkeleton} />
                            </div>
                        ))
                    }
                </div>
                <PageHandler type="button" />
            </Container>
        </div>
    )
}

export default NewsListPage
