import { productViewBreakpoint } from "../../../lib/helper/viewportHelper"
import theme from "../../../theme"
import ProductCard from "../../Products/ProductCard/ProductCard"
import { persistScrollPosition } from "../helperFunctions"
import useStyles from "../styles"
import { Box, useMediaQuery } from "@mui/material"
import React from "react"

const ComparisonHeader = ({ products }) => {
    const classes = useStyles()
    const isMobile = useMediaQuery(theme.breakpoints.down(productViewBreakpoint))

    return (
        <Box className={classes.comparisonHeader}>
            {
                products.map((product, i) => (
                    <Box key={`pc-${i}`} style={{ padding: isMobile ? 0 : 8 }}>
                        <ProductCard
                            // eslint-disable-next-line react/no-array-index-key
                            product={product}
                            handleShowMoreCompsClick={null}
                            listView
                            grid
                            onSelect={persistScrollPosition}
                            compare
                        />
                    </Box>
                ))
            }
        </Box>
    )
}

export default ComparisonHeader
