import axios from "axios"

export const getUserData = (
    authToken,
    callback,
    setLoading = () => { },
    errorCallback = () => { }
) => {
    const fetchData = async () => {
        if (setLoading) setLoading(true)
        let response = {}
        try {
            response = await axios.post(
                "/api/Users/GetCustomerData",
                {
                    authToken
                }
            )
        } catch (error) {
            errorCallback(error)
            if (setLoading) setLoading(false)
            throw error
        }

        if (setLoading) setLoading(false)
        return response.data
    }

    fetchData()
        .then(result => callback(result))
        .catch(error => {
            console.log("Error getting user data.", error)
            setLoading(false)
        })
}

export const checkMonitorArticles = (lang, articles, callback, setLoading) => {
    const fetchJson = async () => {
        if (setLoading) setLoading(true)

        const response = await axios.post("/api/ProductInfo/CheckMonitorProducts", {
            language: lang,
            stringList: articles
        })

        if (setLoading) setLoading(false)
        return response.data
    }

    fetchJson()
        .then(result => callback(result))
        .catch(error => {
            console.log("Error checking monitor products.", error)
            setLoading(false)
        })
}
