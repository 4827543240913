/* eslint-disable no-empty */
// Need to use the React-specific entry point to import createApi
import { addPrices } from "../priceSlice"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const priceApi = createApi({
    reducerPath: "priceApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/ProductInfo/" }),
    endpoints: builder => ({
        getPrices: builder.query({
            query: ({ authToken, ids }) => ({
                url: "GetPrices",
                method: "POST",
                body: {
                    authToken,
                    ids
                }
            }),
            // eslint-disable-next-line no-unused-vars
            async onQueryStarted({ authToken, ids }, { dispatch, queryFulfilled }) {
                try {
                    const { data: prices } = await queryFulfilled
                    dispatch(addPrices(prices))
                } catch (err) { }
            }
        })
    })
})

export const { useGetPricesQuery } = priceApi

//         condition: (params, { getState }) => {
//             const { id, authToken } = params;
//             if(authToken === undefined)
//                 return false; // Do not fetch if customer number is undefined
//             const existing = selectById(getState(), id);
//             return !existing;
//         }
