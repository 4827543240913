import { Box, Typography } from '@mui/material'
import classnames from 'classnames'
import useStyles from '../styles'

const Headers = ({ headerList }) => {
    const classes = useStyles()

    return (
        <Box className={classnames(classes.flexRow, classes.headerRoot)}>
            {headerList.map((title, index) => {
                return (
                    <Box className={classes.flexItem} key={index}>
                        <Typography variant='h6' className={classnames(classes.gray, classes.capitalized)}>
                            {title}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

export default Headers