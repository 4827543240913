// eslint-disable-next-line import/no-cycle
import MobileOrderAddresses from "./OrderAddresses/MobileOrderAddresses"
import OrderAddresses from "./OrderAddresses/OrderAddresses"
import OrderConfirmation from "./OrderConfirmation/OrderConfirmation"
import OrderStepConnector from "./OrderStepConnector/OrderStepConnector"
import OrderStepIcon from "./OrderStepIcon/OrderStepIcon"
import MobileOrderSummary from "./OrderSummary/MobileOrderSummary"
import OrderSummary from "./OrderSummary/OrderSummary"
import useStyles from "./styles"
import theme from "../../theme"
import { strings as Localization } from "../../lib/Localization"
import { tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { useSelector } from "react-redux"
import React from "react"
import {
    Step, StepLabel, Stepper, useMediaQuery
} from "@mui/material"

const Order = () => {
    const classes = useStyles()
    const cart = useSelector(state => state.cart)
    const isSmall = useMediaQuery(theme.breakpoints.down(tabletBreakpoint))

    const activeStep = cart.order.step
    const steps = [Localization.cart, Localization.addressDetails, Localization.confirmation]

    const isStepCompleted = i => activeStep > i

    const renderStep = step => {
        const orderSummary = isSmall ? <MobileOrderSummary /> : <OrderSummary />
        const orderAddresses = isSmall ? <MobileOrderAddresses /> : <OrderAddresses />

        switch (step) {
            case 0:
                return orderSummary
            case 1:
                return orderAddresses
            case 2:
                return <OrderConfirmation />
            default:
                return "error"
        }
    }

    return (
        <div className={classes.content}>
            <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel connector={<OrderStepConnector />}>
                {steps.map((step, i) => {
                    const stepProps = {}
                    const labelProps = {}
                    stepProps.completed = isStepCompleted(i)
                    return (
                        <Step key={step} {...stepProps}>
                            <StepLabel StepIconComponent={OrderStepIcon} {...labelProps}>{step}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            <div className={classes.checkoutWrapper}>
                {renderStep(activeStep)}
            </div>
        </div>
    )
}

export default Order
