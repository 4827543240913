import RegisterRetailerStepIcon from "./RegisterRetailerStepIcon"
import useStyles from "./styles"
import { strings as Localization } from "../../lib/Localization"
import { setSnackbarMessage } from "../../redux/userMessageSlice"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { useRecaptchaSiteKey } from "../../redux/hook/settingsHooks"
import React, { useState, useRef } from "react"
import {
    Box,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Link,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    FormControl,
    FormGroup,
    FormHelperText,
    CircularProgress
} from "@mui/material"
import ReCAPTCHA from "react-google-recaptcha"
// import RegisterRetailerStepConnector from './RegisterRetailerStepConnector';
import { Error, ExpandMore, Send } from "@mui/icons-material"
import { useForm } from "react-hook-form"
import axios from "axios"
import { useDispatch } from "react-redux"

const formDefaultValues = {
    companyName: "",
    organizationNumber: "",
    website: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    deliveryAddress: "",
    deliveryPostalCode: "",
    deliveryTown: "",
    boxDeliveryAddress: "",
    boxDeliveryPostalCode: "",
    boxDeliveryTown: "",
    billingAddress: "",
    billingPostalCode: "",
    billingTown: "",
    billingEmail: "",
    nameSign: "",
    dateSign: "",
    townSign: "",
    registrationCertificate: undefined,
    agreement: false,
    recaptcha: undefined
}

const RegisterRetailer = ({ ...props }) => {
    const {
        register, setValue, setError, clearErrors, handleSubmit, watch, formState: { errors }
    } = useForm({ defaultValues: formDefaultValues })
    const recaptchaRef = useRef()
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const recaptchaSiteKey = useRecaptchaSiteKey()
    const language = useLanguage()
    const classes = useStyles()

    const onChangeRecaptcha = value => {
        if (value) {
            clearErrors("recaptcha")
        }

        setValue("recaptcha", value)
    }

    const onExpired = () => {
        setValue("recaptcha", undefined)
    }

    const handleRegisterRetailer = async data => {
        if (data.recaptcha === undefined) {
            setError("recaptcha", {
                type: "manual",
                message: "Please verify that you are not a robot using Recaptcha!"
            })
            return
        }

        // Move data into a FormData object
        const formData = new FormData()
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value)
        })
        formData.set("registrationCertificate", data.registrationCertificate[0], data.registrationCertificate[0].name)

        setIsLoading(true)
        let status
        try {
            const response = await axios.post(
                `/api/Users/RegisterRetailer?token=${data.recaptcha}`,
                formData,
                { headers: { "content-type": "multipart/form-data" } }
            )
            status = response.status
        } catch (exception) {
            status = exception?.response?.status
        }
        setIsLoading(false)

        switch (status) {
            case 200: {
                dispatch(setSnackbarMessage(Localization.yourApplicationHasBeenSent, "success"))
                return
            }
            case undefined: {
                console.error("Failed to send the request to register retailer.")
                dispatch(setSnackbarMessage(
                    `${Localization.couldNotConnectToTheServer}`,
                    "error"
                ))
                return
            }
            case 401: {
                setError("recaptcha", {
                    type: "manual",
                    message: "Recaptcha returned an error whilst validating!"
                })
                break
            }
            default: {
                setError("recaptcha", {
                    type: "manual",
                    message: "An error occured on the server!"
                })
                break
            }
        }

        console.error(`Failed with the status of ${status} when trying to register retailer.`)
        setValue("recaptcha", undefined)
        recaptchaRef.current.reset()
        dispatch(setSnackbarMessage(
            // eslint-disable-next-line max-len
            `${Localization.aProblemOccuredWhileProccessingYourApplication} ${Localization.pleaseTryAgainLaterOrContactBroditsSupportIfTheProblemPersists}`,
            "error"
        ))
    }

    return (
        <Container maxWidth="md" {...props}>
            <form onSubmit={handleSubmit(handleRegisterRetailer)}>
                <RetailerInformation />
                {
                    language === "sv"
                    && (
                        <>
                            <BusinessSection register={register} errors={errors} />
                            <PurchaceSection register={register} errors={errors} />
                            <DeliveryAdress register={register} errors={errors} />
                            <InvoicingAdress register={register} errors={errors} />
                            <ValidateAndRegisterSection
                                register={register}
                                errors={errors}
                                watch={watch}
                                onChangeRecaptcha={onChangeRecaptcha}
                                onExpired={onExpired}
                                recaptchaRef={recaptchaRef}
                                isLoading={isLoading}
                                recaptchaSiteKey={recaptchaSiteKey}
                            />
                        </>
                    )
                }
            </form>
        </Container>
    )
}

const RetailerInformation = () => (
    <Box style={{ padding: "20 0" }}>
        <Typography variant="h4">{Localization.toSellBroditProducts}</Typography>
        <Typography paragraph variant="body2" style={{ marginTop: 40, marginBottom: 40 }}>
            {Localization.retailerApplicationText}
        </Typography>
    </Box>
)

const BusinessSection = ({ register, errors }) => {
    const [expanded, setExpanded] = React.useState(true)
    const hasErrors = (
        Boolean(errors.companyName)
        || Boolean(errors.organizationNumber)
        || Boolean(errors.website)
    )
    React.useEffect(() => {
        setExpanded(expandedValue => hasErrors || expandedValue)
    }, [hasErrors])
    const classes = useStyles()

    return (
        <Accordion expanded={expanded} onChange={(_event, expandedValue) => { setExpanded((expandedValue || hasErrors)) }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <RegisterRetailerStepIcon completed icon={2} />
                    <Typography variant="h5" style={{ margin: "auto 20px" }}>{Localization.companyAdress}</Typography>
                    {hasErrors && <Error color="error" />}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box className={classes.textFieldWrapper}>
                    <TextField
                        {...register("companyName", { required: true })}
                        error={Boolean(errors.companyName)}
                        label={Localization.companyName}
                    />
                    <TextField
                        {...register("organizationNumber", { required: true })}
                        error={Boolean(errors.organizationNumber)}
                        label={Localization.organisationNumber}
                    />
                    <TextField
                        {...register("website", { required: true })}
                        error={Boolean(errors.website)}
                        label={Localization.website}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

const PurchaceSection = ({ register, errors }) => {
    const [expanded, setExpanded] = React.useState(false)
    const hasErrors = (
        Boolean(errors.contactName)
        || Boolean(errors.contactPhone)
        || Boolean(errors.contactEmail)
    )
    React.useEffect(() => {
        setExpanded(expandedValue => hasErrors || expandedValue)
    }, [hasErrors])
    const classes = useStyles()

    return (
        <Accordion expanded={expanded} onChange={(_event, expandedValue) => { setExpanded((expandedValue || hasErrors)) }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <RegisterRetailerStepIcon completed icon={3} />
                    <Typography variant="h5" style={{ margin: "auto 20px" }}>{Localization.purchase}</Typography>
                    {hasErrors && <Error color="error" />}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box className={classes.textFieldWrapper}>
                    <TextField {...register("contactName", { required: true })} error={Boolean(errors.contactName)} label={Localization.contactPerson} />
                    <TextField {...register("contactPhone", { required: true })} error={Boolean(errors.contactPhone)} label={Localization.phonenumber} />
                    <TextField {...register("contactEmail", { required: true })} error={Boolean(errors.contactEmail)} type="email" label={Localization.email} />
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

const DeliveryAdress = ({ register, errors }) => {
    const [expanded, setExpanded] = React.useState(false)
    const hasErrors = (
        Boolean(errors.deliveryAddress)
        || Boolean(errors.deliveryPostalCode)
        || Boolean(errors.deliveryTown)
    )
    React.useEffect(() => {
        setExpanded(expandedValue => hasErrors || expandedValue)
    }, [hasErrors])
    const classes = useStyles()

    return (
        <Accordion expanded={expanded} onChange={(_event, expandedValue) => { setExpanded((expandedValue || hasErrors)) }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <RegisterRetailerStepIcon completed icon={4} />
                    <Typography variant="h5" style={{ margin: "auto 20px" }}>{Localization.delivery}</Typography>
                    {hasErrors && <Error color="error" />}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box className={classes.textFieldWrapper}>
                    <TextField
                        {...register("deliveryAddress", { required: true })}
                        error={Boolean(errors.deliveryAddress)}
                        label={Localization.streetAddress}
                        helperText={Localization.forParcelDelivery}
                    />
                    <TextField
                        {...register("deliveryPostalCode", { required: true })}
                        error={Boolean(errors.deliveryPostalCode)}
                        label={Localization.postalCode}
                    />
                    <TextField
                        {...register("deliveryTown", { required: true })}
                        error={Boolean(errors.deliveryTown)}
                        label={Localization.town}
                        style={{ marginBottom: 20 }}
                    />
                    <TextField
                        {...register("boxDeliveryAddress")}
                        label={Localization.boxDeliveryAddress}
                        helperText="För brevpaket"
                    />
                    <TextField
                        {...register("boxDeliveryPostalCode")}
                        label={Localization.postalAddress}
                    />
                    <TextField
                        {...register("boxDeliveryTown")}
                        label={Localization.town}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

const InvoicingAdress = ({ register, errors }) => {
    const [expanded, setExpanded] = React.useState(false)
    const hasErrors = (
        Boolean(errors.billingAddress)
        || Boolean(errors.billingPostalCode)
        || Boolean(errors.billingTown)
        || Boolean(errors.billingEmail)
    )
    const classes = useStyles()

    React.useEffect(() => {
        setExpanded(expandedValue => hasErrors || expandedValue)
    }, [hasErrors])

    return (
        <Accordion expanded={expanded} onChange={(_event, expandedValue) => { setExpanded((expandedValue || hasErrors)) }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <RegisterRetailerStepIcon completed icon={5} />
                    <Typography variant="h5" style={{ margin: "auto 20px" }}>{Localization.billingAddress}</Typography>
                    {hasErrors && <Error color="error" />}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box className={classes.textFieldWrapper}>
                    <TextField
                        {...register("billingAddress", { required: true })}
                        error={Boolean(errors.billingAddress)}
                        label={Localization.streetAddress}
                        helperText="för företagspaket"
                    />
                    <TextField
                        {...register("billingPostalCode", { required: true })}
                        error={Boolean(errors.billingPostalCode)}
                        label={Localization.postalCode}
                    />
                    <TextField
                        {...register("billingTown", { required: true })}
                        error={Boolean(errors.billingTown)}
                        label={Localization.town}
                    />
                    <TextField
                        {...register("billingEmail", { required: true })}
                        error={Boolean(errors.billingEmail)}
                        label={Localization.email}
                        helperText={Localization.forInvoiceThroughPdfBroditOnlySendsInvoicesThroughEmail}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

const ValidateAndRegisterSection = ({
    register, errors, watch, onChangeRecaptcha, onExpired, recaptchaRef, isLoading, recaptchaSiteKey
}) => {
    const registrationCertificateWatch = watch("registrationCertificate")
    const lang = useLanguage()
    const hasErrors = (
        Boolean(errors.agreement)
        || Boolean(errors.registrationCertificate)
        || Boolean(errors.recaptcha)
    )
    const classes = useStyles()

    return (
        <Accordion expanded>
            <AccordionSummary>
                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <RegisterRetailerStepIcon completed icon={6} />
                    <Typography variant="h5" style={{ margin: "auto 20px" }}>{Localization.validate}</Typography>
                    {hasErrors && <Error color="error" />}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box style={{
                    padding: "20px", display: "flex", flexDirection: "column"
                }}
                >
                    <Box className={classes.textFieldWrapperNoPadding}>
                        <Typography>{Localization.companySignatory}</Typography>
                        <TextField {...register("nameSign", { required: true })} label={Localization.name} />
                        <TextField {...register("dateSign", { required: true })} label={Localization.date} />
                        <TextField {...register("townSign", { required: true })} label={Localization.town} />
                    </Box>
                    <Box style={{
                        marginTop: 40, marginBottom: 40, display: "flex", flexDirection: "column"
                    }}
                    >
                        <Typography
                            style={{ marginBottom: "6px" }}
                        >
                            {Localization.attachUpToDateRegistrationCertificateFromBolagsverketAsAFile}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <Button
                                variant="contained"
                                component="label"
                            >
                                {Localization.chooseAFile}
                                <input {...register("registrationCertificate", { required: true })} type="file" hidden accept="application/pdf" />
                            </Button>
                            <Typography
                                style={{ paddingLeft: "10px" }}
                                variant="body2"
                                color={
                                    errors.registrationCertificate
                                        ? "error"
                                        : "textSecondary"
                                }
                            >
                                {registrationCertificateWatch?.[0]?.name ?? Localization.noFileHasBeenSelected}
                            </Typography>
                        </Box>
                    </Box>
                    <FormControl required error={Boolean(errors.agreement)}>
                        <FormGroup>
                            <FormControlLabel
                                control={(
                                    <Checkbox {...register(
                                        "agreement",
                                        {
                                            required: true,
                                            validate: value => value === true
                                        }
                                    )}
                                    />
                                )}
                                label={(
                                    <Typography>
                                        {Localization.iHaveReadAndAgreeTo}
                                        {" "}
                                        <Link href="https://www.brodit.se/content/BroditFsgvillkor.pdf" target="_blank" rel="noopener">
                                            {Localization.broditABsConditionsAndTermsOfSale}
                                        </Link>
                                        .
                                    </Typography>
                                )}
                            />
                        </FormGroup>
                        {Boolean(errors.agreement) && (
                            <FormHelperText>
                                {Localization.pleaseAcceptTheTermsAndConditionsAboveToContinue}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        style={{ marginTop: "20px" }}
                        sitekey={recaptchaSiteKey}
                        onExpired={onExpired}
                        onChange={onChangeRecaptcha}
                        hl={lang}
                    />
                    {Boolean(errors.recaptcha) && <Typography color="error">{Localization.pleaseFillInThisCheckBox}</Typography>}
                    <Button
                        style={{ marginTop: 40, alignSelf: "start" }}
                        variant="contained"
                        type="submit"
                        disabled={isLoading || !watch("recaptcha")}
                    >
                        {Localization.sendApplication}
                        <Box sx={{
                            minWidth: 100, minHeight: 50, display: "flex", justifyContent: "center", alignItems: "center"
                        }}
                        >
                            {isLoading ? <CircularProgress /> : <Send />}
                        </Box>
                    </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default RegisterRetailer
