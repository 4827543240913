import React from 'react'
import { Helmet } from 'react-helmet'

/**
 * Prevents google from indexing this route.
 */
function NoIndexMetaData() {
    return (
        <Helmet>
            <meta name="robots" content="noindex" />
        </Helmet>
    )
}

export default NoIndexMetaData